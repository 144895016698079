import React from 'react';

import cn from 'classnames';

import 'scss/skin/aside.scss';

import type { AsideStaticProps } from './types';
import type { FC } from 'react';

const Static: FC<AsideStaticProps> = ({ children, className }) => (
    <section className={cn(className, 'aside__static')}>
        {children}
    </section>
);

export default Static;
