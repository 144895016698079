import React from 'react';

import photon from 'photon';

import { IMAGE_FORMAT_MAP } from 'atoms/Picture/constants';

import type { AvailablePictureTypes, BaseProps } from 'atoms/Picture/types';
import type { FC } from 'react';

interface Props extends Pick<BaseProps, 'src'>{
    types: readonly AvailablePictureTypes[];
}

const BlurSources: FC<Props> = ({ src, types }) => (
    <>
        {types.map((type) => (
            <source
                key={`blur-${type}`}
                type={`image/${type}`}
                srcSet={photon(src, 100, 0, 1, IMAGE_FORMAT_MAP[type], 0)}
            />
        ))}
    </>
);

export default BlurSources;
