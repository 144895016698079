(function initConfig(): void {
    if (document.domain.endsWith('corp.mail.ru')) {
        const regex = /^(.*?-)?(.+?)(\.games\.gm[123]\.corp\.mail\.ru)$/;
        const browserException = /PhantomJS/;
        const allowToShow = !browserException.test(window.navigator.userAgent);
        const match = regex.exec(window.location.hostname);
        window.gamesMailRuAppConfig = {
            pathPrefix: '/pc',
            restPrefix: '/pc',
            restDomain: `${match[2]}-api${match[3]}`,
            desktopHost: match[2] + match[3],
            imgHost: match[2] + match[3],
            mobileHost: `m-${match[2]}${match[3]}`,
            useMobileCheck: false,
            profile: false,
            isGamecenter: false,
            adminPreviewParam: 'admin_preview',
            selfLoaded: true,
            hotCommentCount: 50,
            allowToShow,
        };
    } else if (document.domain.includes('media.dev-vkplay.')) { // STAGE
        window.gamesMailRuAppConfig = {
            desktopHost: 'media.vkplay.ru',
            imgHost: 'vkplay.ru',
            pathPrefix: '',
            restPrefix: '/pc',
            restDomain: 'api.dev-vkplay.ru',
        };
    } else if (document.domain.endsWith('gm2.vkplay.ru') || document.domain.endsWith('gm3.dev-vkplay.ru')) { // DEV
        const regex = /^(.*?-)?(.+?)(-media\.gm[23]\.dev-vkplay\.ru)/;
        const match = regex.exec(window.location.hostname);
        window.gamesMailRuAppConfig = {
            desktopHost: 'media.vkplay.ru',
            imgHost: 'vkplay.ru',
            pathPrefix: '',
            restPrefix: '/pc',
            restDomain: `${match[2]}-api.gm3.dev-vkplay.ru`,
        };
    } else if (document.domain.includes('.vkplay.')) {
        window.gamesMailRuAppConfig = {
            desktopHost: 'media.vkplay.ru',
            imgHost: 'vkplay.ru',
            pathPrefix: '',
            restPrefix: '/pc',
            restDomain: 'api.vkplay.ru',
        };
    } else {
        const browserException = /PhantomJS/;
        const allowToShow = !browserException.test(window.navigator.userAgent);
        window.gamesMailRuAppConfig = {
            pathPrefix: '/pc',
            restPrefix: '/pc',
            restDomain: 'api.games.mail.ru',
            desktopHost: 'games.mail.ru',
            imgHost: 'games.mail.ru',
            mobileHost: 'm.games.mail.ru',
            useMobileCheck: false,
            profile: false,
            isGamecenter: false,
            adminPreviewParam: 'admin_preview',
            selfLoaded: true,
            hotCommentCount: 50,
            allowToShow,
        };
    }
}());
