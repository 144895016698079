import React, { memo, useState } from 'react';

import cn from 'classnames';

import BlurSources from 'atoms/Picture/components/BlurSources';
import Sources from 'atoms/Picture/components/Sources';
import { IMAGE_TYPES } from 'atoms/Picture/constants';

import 'scss/skin/picture.scss';

import type { BaseProps } from 'atoms/Picture/types';

const Picture: React.FC<BaseProps> = ({
    className,
    classNameWrapper,
    src,
    alt = '',
    title,
    width = 0,
    height = 0,
    mobWidth,
    mobHeight,
    quality = 85,
    noBlur,
    blurOnly,
    noGif,
    originalWidth,
    originalHeight,
    lazy,
    originalObjectFit = 'cover',
}) => {
    const [blurImageLoaded, setBlurImageLoaded] = useState(false);
    const [originalImageLoaded, setOriginalImageLoaded] = useState(false);

    const handleBlurImageLoaded = (): void => {
        setBlurImageLoaded(true);
    };

    const handleOriginalImageLoaded = (): void => {
        setOriginalImageLoaded(true);
    };

    const showBlurPicture = !noBlur;
    const showOriginalImage = !blurOnly && (noBlur || blurImageLoaded);

    return (
        <div className={cn('picture-wrapper', classNameWrapper)}>
            { showBlurPicture && (
                <picture className={cn(className, 'picture', 'picture_blur')}>
                    <BlurSources src={src} types={IMAGE_TYPES} />
                    <img
                        className="picture__img"
                        src={src}
                        alt={alt}
                        title={title}
                        loading={lazy ? 'lazy' : 'eager'}
                        width={originalWidth}
                        height={originalHeight}
                        onLoad={handleBlurImageLoaded}
                        onError={handleBlurImageLoaded}
                    />
                </picture>
            )}
            { showOriginalImage && (
                <picture
                    className={cn(
                        className,
                        'picture',
                        'picture__origin',
                        {
                            'picture__origin_absolute-positioned': !noBlur,
                            [`picture__object-fit_${originalObjectFit}`]: originalObjectFit,
                        },
                    )}
                >
                    <Sources
                        src={src}
                        types={IMAGE_TYPES}
                        height={height}
                        width={width}
                        mobHeight={mobHeight}
                        mobWidth={mobWidth}
                        quality={quality}
                        noGif={noGif}
                    />
                    <img
                        className={cn(
                            'picture__img',
                            'picture__original-img',
                            { 'picture__original-img_loaded': originalImageLoaded },
                        )}
                        src={src}
                        alt={alt}
                        title={title}
                        loading={lazy ? 'lazy' : 'eager'}
                        width={originalWidth}
                        height={originalHeight}
                        onLoad={handleOriginalImageLoaded}
                        onError={handleOriginalImageLoaded}
                    />
                </picture>
            )}
        </div>
    );
};

export default memo(Picture);
