import React, { useEffect } from 'react';

import { useBanners } from 'contexts/banners';

import Head from 'atoms/Head';

import QuizWidget from 'organisms/Quiz';
import useQuizData from 'organisms/Quiz/useQuizData';

import 'scss/skin/quiz-page.scss';

import type { FC } from 'react';

const QUIZ_ID = 30;
const QUIZ_TYPE = 'trivia';

const QuizVKP: FC = () => {
    const { data, checkAnswer, checkResults } = useQuizData({ id: QUIZ_ID, type: QUIZ_TYPE });
    const {
        addBannersDisableCondition,
        removeBannersDisableCondition,
    } = useBanners();

    useEffect(() => {
        hideHeaderAndFooter();
        addBannersDisableCondition('is-quiz-page');

        return () => {
            removeBannersDisableCondition('is-quiz-page');
        };
    }, []);

    function hideHeaderAndFooter(): void {
        const header = document.getElementById('vkp-header');
        const footer = document.getElementById('vkp-footer');
        const informer = document.getElementById('top-informer');

        if (header) {
            header.style.display = 'none';
        }

        if (footer) {
            footer.style.display = 'none';
        }

        if (informer) {
            informer.style.display = 'none';
        }
    }

    return (
        <main className="quiz-page">
            <Head
                title={data.title || 'Викторина VK Play'}
                description={data?.descr || ''}
                image={data?.image || ''}
            />
            <h1 className="quiz-page__title">{data.title}</h1>
            <QuizWidget
                {...data}
                checkAnswer={checkAnswer}
                checkResults={checkResults}
            />
            {data.id && <div id="prerenderAnchor" />}
        </main>
    );
};

export default QuizVKP;
