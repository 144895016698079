import React, { Suspense } from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import Authorised from 'layouts/Authorised';
import getQuiz from 'src/api/v1/getQuiz';
import getQuizPreviousResult from 'src/api/v1/getQuizPreviousResult';
import getTrivia from 'src/api/v1/getTrivia';
import getTriviaPreviousResult from 'src/api/v1/getTriviaPreviousResult';
import postQuizResults from 'src/api/v1/postQuizResults';
import postTriviaCheck from 'src/api/v1/postTriviaCheck';
import postTriviaResults from 'src/api/v1/postTriviaResults';
import ReactGa from 'utils/ReactGa';

import Head from 'atoms/Head';
import Picture from 'atoms/Picture';
import Social from 'atoms/Social';
// import WidgetsRenderer from './WidgetsRenderer';

import { getSearchParameter } from 'neurons';

import '../scss/quiz.scss';

import { ReactComponent as ReplaySvg } from 'img/replay.svg';

const BetaKey = React.lazy(() => import('molecules/Betakey'));

const resultId = getSearchParameter('q-result');

async function fetchQuiz(slug, type, callback) {
    if (!slug || !type) {
        return;
    }

    try {
        const response = type === 'trivia'
            ? await getTrivia({ pathParameters: { slug } })
            : await getQuiz({ pathParameters: { slug } });

        if (!callback) {
            return;
        }

        callback(response);
    } catch (error) {
        console.error('fetch error', error);
    }
}

async function checkAnswer(slug, data, callback) {
    if (!slug || !data) {
        return;
    }

    try {
        const response = await postTriviaCheck({
            pathParameters: { slug },
            body: data,
        });

        if (!callback) {
            return;
        }

        callback(response);
    } catch (error) {
        console.error('fetch error', error);
    }
}

async function checkResults(slug, type, data, callback) {
    if (!slug || !data) {
        return;
    }

    try {
        const response = type === 'trivia'
            ? await postTriviaResults({ pathParameters: { slug }, body: data })
            : await postQuizResults({ pathParameters: { slug }, body: data });

        if (!callback) {
            return;
        }

        callback(response);
    } catch (error) {
        console.error('fetch error', error);
    }
}

async function getPreviousResults(slug, type, uuid, callback) {
    if (!slug || !uuid) {
        return;
    }

    try {
        const response = type === 'trivia'
            ? await getTriviaPreviousResult({ pathParameters: { slug, uuid } })
            : await getQuizPreviousResult({ pathParameters: { slug, uuid } });

        if (!callback) {
            return;
        }

        callback(response);
    } catch (error) {
        console.error('fetch error', error);
    }
}

export default class Quiz extends React.PureComponent {
    // eslint-disable-next-line react/static-property-placement
    static propTypes = {
        node: PropTypes.instanceOf(Element),
        id: PropTypes.string.isRequired,
        type: PropTypes.oneOf(['quiz', 'trivia']).isRequired,
    };

    // eslint-disable-next-line react/state-in-constructor
    state = {
        startScreen: {},
        questions: [],
        answers: [],
        current: 0,
        isStarted: false,
        result: {},
        rightAnswers: [],
        answerDescription: '',
        isPrerenderReady: false,
        isCodeInited: false,
    };

    resultsRef = React.createRef(null);

    componentDidMount() {
        const { id, type } = this.props;
        this.hideSocial();
        this.checkPreviousResult();

        fetchQuiz(id, type, (data) => {
            this.setState({
                startScreen: {
                    image: data.image,
                    text: data.html_descr,
                },
                questions: data.questions,
            });
        });
    }

    componentDidUpdate() {
        if (this.state.result.title && !this.state.isCodeInited) {
            const btn = document.getElementsByClassName('BETAKEY')[0];

            if (btn) {
                this.initBetaKey(btn);
            }
        }
    }

    initBetaKey = (btn) => {
        // eslint-disable-next-line no-param-reassign
        btn.style.display = 'none';
        const elem = document.getElementById('rendered_getbeta_btn') || document.createElement('DIV');
        elem.id = 'rendered_getbeta_btn';
        btn.parentNode.insertBefore(elem, btn);
        ReactDOM.render((
            <Authorised>
                <Suspense fallback={null}>
                    <BetaKey gameKey={btn.getAttribute('data-keys_group_id')} />
                </Suspense>
            </Authorised>), elem);

        this.setState({ isCodeInited: true });
    };

    checkPreviousResult = () => {
        if (!resultId) {
            return this.setState({
                isPrerenderReady: true,
            }); // можно кешировать, потому что не нужно ждать смены мета-тегов
        }

        const { id, type } = this.props;

        getPreviousResults(id, type, resultId, (data) => {
            this.setState({
                isStarted: true,
                result: data,
                isPrerenderReady: true,
            });
        });
    };

    // eslint-disable-next-line class-methods-use-this
    hideSocial = () => {
        const social = document.getElementsByClassName('story-controls')[0];

        if (social) {
            social.style.display = 'none';
        }
    };

    handleAnswer = (e) => {
        const { value } = e.currentTarget;
        const { id, type } = this.props;
        const { answers, rightAnswers } = this.state;

        const newAnswers = [...answers, value];

        if (type === 'quiz') {
            this.setState({ answers: newAnswers }, this.nextQuestion);

            return;
        }

        checkAnswer(id, { answer: value }, (data) => {
            const right = [...rightAnswers, String(data.id)];

            this.setState({
                answers: newAnswers,
                rightAnswers: right,
                answerDescription: data.description,
            });
        });
    };

    handleReplay = () => {
        this.setState({
            answers: [],
            current: 0,
            isStarted: false,
            result: {},
            rightAnswers: [],
            isCodeInited: false,
        });

        window.history.pushState({}, null, `${window.location.origin}${window.location.pathname}`);

        ReactGa.event({
            category: 'Quiz',
            action: `Replayed ${this.props.type}-${this.props.id} in ${window.location.pathname}`,
        });
    };

    startQuiz = () => {
        this.setState({ isStarted: true });

        ReactGa.event({
            category: 'Quiz',
            action: `Started ${this.props.type}-${this.props.id} in ${window.location.pathname}`,
        });
    };

    getResults = () => {
        const { answers } = this.state;
        const { id, type, history } = this.props;

        const answersData = { answers };

        checkResults(id, type, answersData, (data) => {
            this.setState({ result: data });

            history.push({
                search: `?q-result=${data.uuid}`,
            });

            ReactGa.event({
                category: 'Quiz',
                action: `Finished ${type}-${id} in ${window.location.pathname}`,
                label: data.title,
            });
        });
    };

    nextQuestion = () => {
        const { current, questions } = this.state;

        if (current + 1 >= questions.length) {
            return this.getResults();
        }

        return this.setState({
            current: current + 1,
            answerDescription: '',
        });
    };

    renderQuiz = () => {
        const { type, defaultMeta } = this.props;
        const {
            startScreen,
            questions,
            answers,
            rightAnswers,
            current,
            isStarted,
            result,
            answerDescription,
            isPrerenderReady,
        } = this.state;

        if (!questions || !questions.length) {
            return null;
        }

        if (!isStarted) {
            const startText = { __html: startScreen.text };

            return (
                <>
                    <Picture
                        className="quiz__image"
                        src={startScreen.image}
                        width={700}
                        height={330}
                        alt={startScreen.text}
                    />
                    {/* eslint-disable-next-line react/no-danger */}
                    <div className="quiz__start-text" dangerouslySetInnerHTML={startText} />
                    <button className="quiz__start-button" onClick={this.startQuiz} type="button">
                        Начать тест
                    </button>
                    {isPrerenderReady && (
                        <div id="prerenderAnchor" />
                    )}
                </>
            );
        }

        if (result.title) {
            const resultDescription = { __html: result.html_descr };

            return (
                <>
                    <Head {...defaultMeta} title={result.title} description={result.descr} image={result.image} />
                    <Picture
                        className="quiz__image"
                        src={result.image}
                        width={700}
                        height={330}
                        alt={result.title}
                    />
                    <p className="quiz__question">{result.title}</p>
                    {/* eslint-disable-next-line react/no-danger */}
                    <div ref={this.resultsRef} className="quiz__result" dangerouslySetInnerHTML={resultDescription} />
                    <div className="quiz__social">
                        Поделитесь результатом с друзьями
                        <Social className="quiz__share-buttons" />
                    </div>
                    <button className="quiz__next-action quiz__replay" onClick={this.handleReplay} type="button">
                        <ReplaySvg className="quiz__replay-svg" />
                        Пройти заново
                    </button>
                </>
            );
        }

        const currentQuestion = current + 1;
        const isLast = currentQuestion >= questions.length;

        return (
            <>
                <p className="quiz__count">
                    {currentQuestion}
                    /
                    {questions.length}
                </p>
                <Picture
                    className="quiz__image"
                    src={questions[current].image}
                    width={700}
                    height={330}
                    alt={questions[current].text}
                />
                <p className="quiz__question">{questions[current].text}</p>
                {questions[current].answers.map((answer) => (
                    <button
                        key={answer.id}
                        onClick={currentQuestion !== answers.length ? this.handleAnswer : null}
                        className={cn('quiz__answer', {
                            quiz__answer_disabled: currentQuestion === answers.length,
                            quiz__answer_chosen: answers.includes(String(answer.id)),
                            quiz__answer_right: rightAnswers.includes(String(answer.id)),
                            quiz__answer_wrong: !rightAnswers.includes(String(answer.id)) && type !== 'quiz',
                        })}
                        value={answer.id}
                        type="button"
                    >
                        <span className="quiz__marker" />
                        <span className="quiz__answer-text">
                            {answer.text}
                            {answerDescription && (
                                <span
                                    className="quiz__answer-descr"
                                    dangerouslySetInnerHTML={{ __html: answerDescription }}
                                />
                            )}
                        </span>
                    </button>
                ))}
                {type === 'trivia' && (
                    <button className="quiz__next-action" onClick={this.nextQuestion} type="button">
                        {isLast ? 'Узнать результат' : 'Следующий вопрос'}
                    </button>
                )}
            </>
        );
    };

    render() {
        const { node } = this.props;

        if (node) {
            return ReactDOM.createPortal(this.renderQuiz(), node);
        }

        return (
            <div className="quiz">
                {this.renderQuiz()}
            </div>
        );
    }
}
