import React from 'react';

import { ALLOWED_BUTTON_TYPES, STATIC_BUTTONS } from './constants';
import GameButton from './GameButton';

import styles from './game-buttons.module.scss';

import type { GameButtonsProps } from './types';
import type { FC } from 'react';

const GameButtons: FC<GameButtonsProps> = ({
    buttons,
    light,
    disabledButtons = [],
}) => {
    function getAllowedButtonsData(buttonList: GameButtonsProps['buttons']) {
        return buttonList.filter(
            (button) => (
                ALLOWED_BUTTON_TYPES.includes(button.type)
                && !disabledButtons.includes(button.type)
            ),
        );
    }

    // TODO: убрать фильтр после миграции в рамках ALLIANCE-62021
    const fetchedButtons = getAllowedButtonsData(buttons.filter((button) => button.type !== 'cloud'));
    const staticButtons = getAllowedButtonsData(STATIC_BUTTONS);

    const buttonLists = [
        ...fetchedButtons,
        ...staticButtons,
    ];

    return (
        <div className={styles.root}>
            {buttonLists.map((button) => (
                <GameButton
                    key={button.type}
                    type={button.type}
                    url={button.url}
                    light={light}
                    big={button.isStatic}
                />
            ))}
        </div>
    );
};

export default GameButtons;
