import React from 'react';

import cn from 'classnames';

import Static from './AsideStatic';
import Sticky from './AsideSticky';

import 'scss/skin/aside.scss';

import type { CombinedComponent } from './types';

const Aside: CombinedComponent = ({ className, children }) => (
    <aside className={cn('aside', className)}>
        {children}
    </aside>
);

Aside.Static = Static;
Aside.Sticky = Sticky;

export default Aside;
