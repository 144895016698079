import type { URLType, PossiblePathParameters } from './types';

const replacePathParameters = <P extends PossiblePathParameters>(url: URLType, pathParameters: P) => {
    if (typeof url === 'string') {
        return url.replace(/{(\w*)}/gi, (_, key) => {
            if (!(key in pathParameters)) {
                throw Error('All path parameters were not passed');
            }

            return pathParameters[key];
        });
    }

    return url;
};

export default replacePathParameters;
