import React from 'react';

import cn from 'classnames';

import 'scss/skin/seo-block.scss';

const SeoBlock: React.FunctionComponent = ({ text, isLight }) => (
    <p className={cn('seo-block', { 'seo-block_light': isLight })}>{text}</p>
);

export default SeoBlock;
