import React, { useMemo } from 'react';

import { platforms } from 'aliases';

import { ReactComponent as AndroidSvg } from 'img/platforms/android.svg';
import { ReactComponent as IosSvg } from 'img/platforms/ios.svg';
import { ReactComponent as MacSvg } from 'img/platforms/mac.svg';
import { ReactComponent as PcWindowsSvg } from 'img/platforms/pc_windows.svg';
import { ReactComponent as Playstation5Svg } from 'img/platforms/playstation-5.svg';
import { ReactComponent as Playstation2Svg } from 'img/platforms/playstation_2.svg';
import { ReactComponent as Playstation3Svg } from 'img/platforms/playstation_3.svg';
import { ReactComponent as Playstation4Svg } from 'img/platforms/playstation_4.svg';
import { ReactComponent as WiiSvg } from 'img/platforms/wii.svg';
import { ReactComponent as Xbox360Svg } from 'img/platforms/xbox_360.svg';
import { ReactComponent as XboxOneSvg } from 'img/platforms/xbox_one.svg';
import { ReactComponent as XboxSeriesXSvg } from 'img/platforms/xbox_one_x.svg';

import 'scss/skin/platform-tag.scss';

const iconsMap = {
    playstation_2: <Playstation2Svg />,
    playstation_3: <Playstation3Svg />,
    playstation_4: <Playstation4Svg />,
    'playstation-5': <Playstation5Svg />,
    xbox_360: <Xbox360Svg />,
    xbox_one: <XboxOneSvg />,
    'xbox-series-x': <XboxSeriesXSvg />,
    wii: <WiiSvg />,
    wii_u: <WiiSvg />,
    pc_windows: <PcWindowsSvg />,
    mac: <MacSvg />,
    android: <AndroidSvg />,
    ios: <IosSvg />,
    iphone: <IosSvg />,
    ipad: <IosSvg />,
};

type Props = {
    type: string;
}

export default function PlatformTag({ type }: Props) {
    const icon = useMemo(() => {
        if (!Object.prototype.hasOwnProperty.call(iconsMap, type)) {
            return null;
        }

        const Icon = iconsMap[type];

        return (
            <span className="platform-tag__icon">
                {Icon}
            </span>
        );
    }, [type]);

    return (
        <span className="platform-tag">
            {icon}
            <span className="platform-tag__name">{platforms[type]}</span>
        </span>
    );
}
