import React, { useState } from 'react';

import PrerenderContext from 'contexts/prerender';

const Prerendered: React.FC = ({ children }) => {
    const [isPrerenderReady, setPrerenderReady] = useState(false);

    return (
        <PrerenderContext.Provider value={{ isPrerenderReady, setPrerenderReady }}>
            {children}
        </PrerenderContext.Provider>
    );
};

export default React.memo(Prerendered);
