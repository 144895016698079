import React, { useContext } from 'react';

import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import AuthContext from 'contexts/auth';
import { useUpdateCounter } from 'contexts/updateCounter';

import NavigationVKP from '@VKP/Navigation';
import { gameSpecialPages } from 'aliases';
import Navigational from 'layouts/Navigational';
import Prerendered from 'layouts/Prerendered';
import { IS_PRODUCTION } from 'src/constants';
import { sendPageView as sendPageViewTMR, sendPageViewProxy } from 'src/TopMailCounter';
import getDisplayMode from 'utils/getDisplayMode';
import ReactGa from 'utils/ReactGa';

import Game from 'pages/Game';
import Games from 'pages/Games';
import ErrorPage from 'src/pages/ErrorPage';
import QuizVKP from 'src/pages/QuizVKP';

import Head from 'atoms/Head';

import Informer from 'src/molecules/Informer';

const Home = React.lazy(() => import('@VKP/pages/Home'));
const StoryV2 = React.lazy(() => import('templates/StoryV2'));
const Strip = React.lazy(() => import('templates/Strip'));
const Story = React.lazy(() => import('pages/Story'));
const UGC = React.lazy(() => import('pages/UGC'));
const Team = React.lazy(() => import('pages/Team'));
const Files = React.lazy(() => import('pages/articles/file'));
const Gof = React.lazy(() => import('pages/gof'));
const Swagger = React.lazy(() => import('pages/Swagger'));
const NotFound = React.lazy(() => import('pages/NotFound'));

let loadCounter = 0;

const Router: React.FC = () => {
    const { user } = useContext(AuthContext);
    const { incrementUpdateCounter } = useUpdateCounter();

    return (
        <BrowserRouter basename={window.isGamecenter ? '/gamecenter' : '/'}>
            {!window.isGamecenter && !window.isSTB && <Informer />}
            <NavigationVKP />
            <Navigational>
                <Prerendered>
                    <MantineProvider
                        theme={{
                            colorScheme: 'dark',
                        }}
                    >
                        <Notifications position="top-right" zIndex={99999} />
                        <Head
                            image="https://vkplay.ru/hotbox/images/vk_600x600.png"
                            imageWidth={600}
                            imageHeight={600}
                        />
                        <Switch>
                            <Route
                                exact
                                path="/"
                                component={Home}
                            />
                            <Route
                                exact
                                path="/team-news/"
                                render={(props) => <Team {...props} type="vkpNews" />}
                            />
                            <Route
                                exact
                                path="/team-articles/"
                                render={(props) => <Team {...props} type="vkpArticles" />}
                            />
                            <Route
                                exact
                                path="/news/"
                                render={(props) => <Strip {...props} type="vkp-news" />}
                            />
                            <Route
                                exact
                                path="/news/:date/"
                                render={(props) => {
                                    const { match: { params: { date } } } = props;

                                    return <Strip {...props} type="vkp-news" date={date} />;
                                }}
                            />
                            <Route
                                exact
                                path="/articles/"
                                render={(props) => <Strip {...props} type="vkp-feat" majorType="articles" />}
                            />
                            <Route
                                exact
                                path="/articles/file"
                                component={Files}
                            />
                            <Route
                                exact
                                path="/articles/feat/"
                                render={(props) => <Strip {...props} type="vkp-feat" majorType="articles" />}
                            />
                            <Route
                                exact
                                path="/articles/secret/"
                                render={(props) => <Strip {...props} type="vkp-secret" majorType="articles" />}
                            />
                            <Route
                                exact
                                path="/articles/feat/viktorina-vk-play/"
                                component={QuizVKP}
                            />
                            <Route
                                exact
                                path="/articles/:alias/"
                                render={(props) => <Strip {...props} majorType="articles" />}
                            />
                            <Route
                                exact
                                path="/gof/"
                                component={Gof}
                            />
                            <Route
                                exact
                                path="/gof/:slug/"
                                render={(props) => {
                                    const { match: { params: { slug } } } = props;

                                    return <StoryV2 {...props} type="gof" alias="gof" slug={slug} />;
                                }}
                            />
                            <Route
                                exact
                                path="/video/"
                                render={(props) => <Strip {...props} type="video" />}
                            />
                            <Route
                                exact
                                path="/video/:slug/"
                                render={(props) => {
                                    const { match: { params: { slug } } } = props;

                                    return <StoryV2 {...props} type="video" alias="video" slug={slug} />;
                                }}
                            />
                            <Route
                                exact
                                path="/industry/"
                                render={(props) => <Strip {...props} type="industry" getParam="&tag=industry" />}
                            />
                            <Route
                                exact
                                path="/industry/:alias/"
                                render={(props) => <Strip {...props} majorType="industry" getParam="&tag=industry" />}
                            />
                            <Route
                                exact
                                path="/games/"
                                render={(props) => <Games {...props} majorType="games" />}
                            />
                            <Route
                                path="/games/:alias/:slug?"
                                render={(props) => {
                                    const { match: { params: { alias, slug } } } = props;

                                    if (gameSpecialPages.includes(alias)) {
                                        return <Games {...props} alias={alias} slug={slug} majorType="games" />;
                                    }

                                    return <Game {...props} slug={alias} tab={slug} />;
                                }}
                            />
                            <Route
                                exact
                                path="/ugc/my/"
                                component={UGC}
                            />
                            <Route
                                path="/:type/:alias/:slug/"
                                component={Story}
                            />
                            <Route
                                path="/error/"
                                component={ErrorPage}
                            />
                            {!IS_PRODUCTION && (
                                <Route
                                    exact
                                    path="/api/"
                                    component={Swagger}
                                />
                            )}
                            <Route path="*">
                                <NotFound />
                            </Route>
                        </Switch>
                    </MantineProvider>
                </Prerendered>
            </Navigational>
            <Route
                path="/"
                render={({ history, location }): void => {
                    if (loadCounter > 0) {
                        const prevUrl = history.prevPage;
                        const currentUrl = location.pathname;

                        if (prevUrl !== currentUrl || location.search.includes('q=')) {
                            ReactGa.pageview(
                                location.pathname + location.search,
                                document.title,
                                getDisplayMode(),
                            ); // TODO: fix title

                            try {
                                const tmrOpts = {
                                    url: location.href,
                                    start: (new Date()).getTime(),
                                    referrer: history.prevPage,
                                    userId: user?.user_id,
                                };

                                sendPageViewTMR({
                                    counter: 'p1',
                                    options: tmrOpts,
                                });

                                sendPageViewTMR({
                                    counter: 'common',
                                    options: tmrOpts,
                                });

                                sendPageViewTMR({
                                    counter: 'common-2',
                                    options: tmrOpts,
                                });

                                sendPageViewProxy({
                                    counter: 'nested',
                                    options: tmrOpts,
                                });

                                sendPageViewProxy({
                                    counter: 'nestedProxy',
                                    options: tmrOpts,
                                });
                            } catch (err) {
                                console.error(`TMR Error: ${err.message}`);
                            }
                        }

                        if (prevUrl !== currentUrl) { // отдельное условие, чтобы не было рекурсивного вызова
                            incrementUpdateCounter();
                        }
                    }

                    loadCounter += 1;
                    // eslint-disable-next-line no-param-reassign
                    history.prevPage = location.pathname;

                    return null;
                }}
            />
        </BrowserRouter>
    );
};

export default React.memo(Router);
