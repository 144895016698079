import { JSON_BODY_HEADERS } from './constants';
import RequestError from './RequestError';
import { getRequestBodyData } from './utils';

import type {
    URLType,
    PossibleRequestBody,
    RequestOptions,
} from './types';

// TODO replace with BASE_URL from webpack
const { restDomain } = window.gamesMailRuAppConfig;

const BASE_API_URL = `//${restDomain}`;

const request = async <R = unknown, B extends PossibleRequestBody = PossibleRequestBody>(
    url: URLType,
    options?: RequestOptions<B>,
) => {
    const {
        method = 'GET',
        body: initialBody,
        headers: initialHeaders,
        ...restOptions
    } = options || {};

    const isGetMethod = method === 'GET';

    const { isJson, body } = getRequestBodyData(initialBody);

    const headers = isJson && !isGetMethod
        ? { ...JSON_BODY_HEADERS, ...initialHeaders }
        : initialHeaders;

    const response = await fetch(`${BASE_API_URL}${url}`, {
        credentials: 'include',
        method,
        headers,
        body,
        ...restOptions,
    });

    const contentType = response.headers.get('content-type');
    const isJsonContent = contentType ? contentType.indexOf('application/json') !== -1 : false;

    const responseBody: R = isJsonContent
        ? await response.json()
        : await response.text();

    if (!response.ok) {
        throw new RequestError({ status: response.status, body: responseBody });
    }

    return responseBody;
};

export default request;
