import React, { useState, useEffect } from 'react';

import cn from 'classnames';

import getPocketBreaking from 'src/api/v4/getPocketBreaking';
import ReactGa from 'utils/ReactGa';

import CustomLink from 'atoms/CustomLink';

import { relativize } from 'neurons';

import { ReactComponent as CloseSvg } from 'img/informer-close.svg';
import { ReactComponent as FlameSvg } from 'img/informer-flame.svg';

import 'scss/skin/informer.scss';

import type { BreakingBanner } from 'entities/BreakingBanner';

const Informer: React.FC = () => {
    const [data, setData] = useState<BreakingBanner>();
    const [isClosed, setClosed] = useState(false);

    const fetchPocketBreaking = async () => {
        try {
            const { result } = await getPocketBreaking({});

            if (!result) {
                return;
            }

            setData(result);

            const isActivePage = window.location.href.includes(result.url.slice(-10));

            if (isActivePage) {
                setClosed(true);
            }
        } catch (error) {
            // ignore
        }
    };

    useEffect(() => {
        fetchPocketBreaking();
    }, []);

    const handleCloseInformer = () => {
        setClosed(true);

        ReactGa.event({
            category: 'Перетяжка',
            action: data?.title || '',
            label: data?.url || '',
        });
    };

    if (!data) {
        // Показываем пустой контейнер, чтобы его можно было отловить по id и скрыть
        return <div id="top-informer" key="top-informer" />;
    }

    const isOuterLink = data.url.includes('/special/');

    return (
        <div
            id="top-informer"
            key="top-informer"
            className={cn('informer', {
                informer_hidden: isClosed,
            })}
        >
            <CustomLink
                to={isOuterLink ? data.url : relativize(data.url)}
                isOuterLink={isOuterLink}
                targetBlank={isOuterLink}
                onClick={handleCloseInformer}
            >
                <span className="informer__content">
                    <span className="informer__icon">
                        <FlameSvg />
                    </span>
                    <span className="informer__title">{data.title}</span>
                </span>
            </CustomLink>
            <span className="informer__content informer__content_outer">
                <button
                    className="informer__close"
                    onClick={handleCloseInformer}
                    type="button"
                >
                    <CloseSvg />
                </button>
            </span>
        </div>
    );
};

export default Informer;
