import { useLayoutEffect } from 'react';

import ResizeObserver from 'resize-observer-polyfill';

import { useAnchors } from 'contexts/anchors';

import type { FC } from 'react';

const ScrollManager: FC = ({
    id, shouldScrollToHash, page,
}) => {
    const { headers } = useAnchors();

    const scrollContainer = document.getElementById('gc-scrollbar') || window;

    useLayoutEffect(() => {
        const { hash } = window.location;
        deinitAnchorScroll();

        if (!shouldScrollToHash || (shouldScrollToHash && !hash)) {
            scrollContainer.scrollTo(0, 0);

            return;
        }

        let node;

        if (hash.includes('title')) {
            if (!headers?.length) {
                return;
            }

            node = Array.from(headers).find((item) => item.id === hash.replace('#', ''));
        } else {
            node = document.querySelector(hash);
        }

        if (!node) {
            scrollContainer.scrollTo(0, 0);

            return;
        }

        initAnchorScroll(node);

        return deinitAnchorScroll;
    }, [id, page, headers]);

    function mouseEvent(e): void {
        window.scrollDelta += e.deltaY;

        const { scrollY, innerHeight } = window;

        if (scrollY + innerHeight === document.body.scrollHeight) {
            deinitAnchorScroll();
        }
    }

    function touchStartEvent(): void {
        window.prevScrollPosition = window.scrollY;
    }

    function touchMoveEvent(): void {
        window.scrollDelta += window.scrollY - window.prevScrollPosition;
        window.prevScrollPosition = window.scrollY;
    }

    function initAnchorScroll(node): void {
        window.scrollDelta = 0;

        window.addEventListener('wheel', mouseEvent);
        window.addEventListener('touchstart', touchStartEvent);
        window.addEventListener('touchmove', touchMoveEvent);

        initResizeObserver(node);
    }

    function deinitAnchorScroll(): void {
        if (window.resizeObserver) {
            window.resizeObserver.disconnect();
        }

        window.removeEventListener('wheel', mouseEvent);
        window.removeEventListener('touchstart', touchStartEvent);
        window.removeEventListener('touchmove', touchMoveEvent);
    }

    function initResizeObserver(node): void {
        const pageNode = document.querySelector('article.story');

        if (!pageNode) {
            return;
        }

        let prevScroll = node.offsetTop;

        scrollContainer.scrollTo(0, node.offsetTop + window.scrollDelta);

        window.resizeObserver = new ResizeObserver((entries) => {
            window.requestAnimationFrame(() => {
                if (!Array.isArray(entries) || !entries.length) {
                    return;
                }

                entries.forEach(() => {
                    if (node.offsetTop !== prevScroll) {
                        prevScroll = node.offsetTop;
                        scrollContainer.scrollTo(0, node.offsetTop + window.scrollDelta);
                    }
                });
            });
        });

        window.resizeObserver.observe(pageNode);
    }

    return null;
};

export default ScrollManager;
