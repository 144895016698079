import React from 'react';

import cn from 'classnames';

import { BUTTON_LABELS } from './constants';

import styles from './game-buttons.module.scss';

import type { GameButtonProps } from './types';
import type { FC } from 'react';

const GameButton: FC<GameButtonProps> = ({
    type, url, light, big,
}) => (
    <a
        className={cn(
            styles.button,
            styles[type],
            {
                [styles.light]: light,
                [styles.big]: big,
            },
        )}
        href={url}
        target={window.isGamecenter ? '_self' : '_blank'}
        rel="noopener noreferrer"
    >
        {BUTTON_LABELS[type]}
    </a>
);

export default GameButton;
