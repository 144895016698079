import { useEffect, useContext } from 'react';

import NavContext from 'contexts/navigation';

export default function usePageClassName(className): void {
    const { setPageClassName } = useContext(NavContext);

    useEffect(
        () => {
            setPageClassName(className);

            return () => setPageClassName(null);
        },
        [className],
    );
}
