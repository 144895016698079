import React, { useState, useEffect } from 'react';

import { widgetAttrParse } from 'neurons';

import Widget from './Widget';

export default function WidgetsRenderer({ id, parentNode }) {
    const [widgets, setWidgets] = useState(null);

    useEffect(() => {
        if (!id) {
            return;
        }

        initWidgets();
    }, [id]);

    function initWidgets() {
        const parent = parentNode || document;

        const widgetNodes = parent.getElementsByClassName('widget');

        if (!widgetNodes || !widgetNodes.length) {
            return;
        }

        const compoundWidgets = {}; // виджеты, состоящие из нескольких виджетов
        const widgetList = [];
        const widgetsIndex = {};

        for (let i = 0; i < widgetNodes.length; i++) {
            if (!widgetNodes[i].dataset) {
                console.error('Widget Error: No dataset found!');

                return false;
            }

            const widgetWrapper = widgetNodes[i].closest('.widget-wrapper');

            // eslint-disable-next-line @typescript-eslint/no-shadow
            const { id, type, props } = widgetNodes[i].dataset;

            widgetsIndex[type] = typeof widgetsIndex[type] === 'number' ? widgetsIndex[type] + 1 : 0;

            // eslint-disable-next-line no-continue
            if (type === 'gallery') { continue; }

            let parsedProps = {};

            if (props) {
                parsedProps = widgetAttrParse(props, id);
            }

            const widgetData = {
                id,
                type,
                domNode: widgetNodes[i],
                index: widgetsIndex[type],
                ...parsedProps,
            };

            if (!widgetWrapper) { // если виджет не обёрнут в widget-wrapper, рендерим его как есть
                widgetList.push(
                    <Widget key={widgetData.id} {...widgetData} />,
                );
                // eslint-disable-next-line no-continue
                continue;
            }

            // если обёрнут, создаем для него составной виджет, куда положим всех детей widget-wrapper'а
            if (widgetWrapper && !widgetWrapper.wId) {
                widgetWrapper.wId = `compound-widget-${i}`;

                compoundWidgets[widgetWrapper.wId] = {
                    id: widgetWrapper.wId,
                    type: widgetWrapper.dataset.type,
                    domNode: widgetWrapper,
                    compound: [], // в массиве будут данные всех внутренних виджетов
                };
            }

            compoundWidgets[widgetWrapper.wId].compound.push(widgetData);
        }

        // отдельно проходим по всем составным виджетам и рендерим их
        Object.values(compoundWidgets).forEach((widgetData) => {
            widgetList.push(
                <Widget
                    key={widgetData.id}
                    id={widgetData.id}
                    type={widgetData.type}
                    domNode={widgetData.domNode}
                    compoundData={widgetData.compound}
                />,
            );
        });

        setWidgets(widgetList);
    }

    if (!widgets || !widgets.length) {
        return null;
    }

    return widgets;
}
