import React from 'react';

import cn from 'classnames';

import 'scss/skin/game-info.scss';

const GameInfo: React.FunctionComponent = ({
    list, title, isMobile, limit, classNameTitle, classNameItem, children,
}) => {
    if (!children && (!list || !list.length)) {
        return null;
    }

    return (
        <>
            <h3
                className={cn(
                    'game-info__title',
                    classNameTitle,
                    { 'game-info__title_mobile': isMobile },
                )}
            >
                {title}
            </h3>
            <p className={cn(
                'game-info',
                classNameItem,
                { 'game-info_mobile': isMobile },
            )}
            >
                {children || list.map((item, i) => (i < limit) && (
                    <React.Fragment key={item.id || item.slug}>
                        {item.name}
                        <span className="game-info__comma">, </span>
                    </React.Fragment>
                ))}
            </p>
        </>
    );
};

GameInfo.defaultProps = {
    limit: 3,
};

export default GameInfo;
