import React, { useState } from 'react';

import { copyTextToClipboardIE11 } from '@vkplay/shared';
import cn from 'classnames';

import ReactGa from 'utils/ReactGa';
import sendExtraEvents from 'utils/VKPlayCounters';

import Snackbar from 'atoms/Snackbar';

import { ReactComponent as ArrowSvg } from 'img/menu-arrow.svg';
import { ReactComponent as LinkSvg } from 'img/social/link.svg';
import { ReactComponent as OKSvg } from 'img/social/ok.svg';
import { ReactComponent as ShareSvg } from 'img/social/share.svg';
import { ReactComponent as TGSvg } from 'img/social/tg.svg';
import { ReactComponent as VKSvg } from 'img/social/vk.svg';

import 'scss/skin/social.scss';

const buttons = [
    {
        type: 'vk',
        icon: VKSvg,
    },
    {
        type: 'ok',
        icon: OKSvg,
    },
    {
        type: 'tg',
        icon: TGSvg,
    },
];

const Social: React.FunctionComponent = ({ className }) => {
    const [isActive, setActive] = useState(false);
    const [snackLabel, setSnackLabel] = useState<string | undefined>();

    function getTagContent(name: string): string {
        return document.querySelector(`meta[property="og:${name}"]`).content;
    }

    function getUrl(type: string): string {
        const { href } = window.location;
        let url = '';

        if (type === 'vk') {
            // eslint-disable-next-line max-len
            url = `http://vk.com/share.php?url=${href}&title=${getTagContent('title')}&comment=${getTagContent('description')}&image=${getTagContent('image')}&noparse=true`;
        }

        if (type === 'fb') {
            url = `https://www.facebook.com/sharer/sharer.php?u=${href}&noparse=false`;
        }

        if (type === 'ok') {
            url = `https://connect.ok.ru/dk?st.cmd=WidgetSharePreview&st.shareUrl=${href}`;
        }

        if (type === 'tg') {
            url = `https://telegram.me/share/url?url=${href}&text=${getTagContent('title')}`;
        }

        return url;
    }

    function openPopup(url): void {
        const params = {
            width: 600,
            height: 450,
            left: Math.round(window.screen.width / 2 - 600 / 2),
            top: 0,
        };

        if (window.screen.height > params.height) {
            params.top = Math.round(window.screen.height / 3 - params.height / 2);
        }

        const newWindow = window.open(url, 'social-share', `
            left=${params.left},
            top=${params.top},
            width=${params.width},
            height=${params.height},
            personalbar=0,
            toolbar=0,
            scrollbars=1,
            resizable=1
        `);

        if (!newWindow) {
            document.location.href = url;

            return;
        }

        newWindow.focus();
    }

    function share(e): void {
        const { type } = e.currentTarget.dataset;
        const url = getUrl(type);

        ReactGa.event({
            category: 'Share',
            action: `Shared to ${type} from ${window.innerWidth < 1200 ? 'mobile' : 'desktop'}`,
            label: `url: ${window.location.origin}${window.location.pathname}`,
        });

        sendExtraEvents({
            action: 'share_click',
            additionalData: type,
        });

        openPopup(url);
    }

    function onSuccessfulLinkCopy(): void {
        setSnackLabel('Ссылка скопирована!');

        sendExtraEvents({
            action: 'share_click',
            additionalData: 'copy-link',
        });

        toggleSharing();
    }

    async function copyLink(): Promise<void> {
        try {
            await navigator.clipboard.writeText(window.location.href);

            onSuccessfulLinkCopy();
        } catch (err) {
            console.error(err);
        }
    }

    async function copyLinkGC(): Promise<void> {
        copyTextToClipboardIE11(window.location.href, onSuccessfulLinkCopy);
    }

    function toggleSharing(): void {
        if (!isActive) {
            sendExtraEvents({
                action: 'share_block_click',
            });
        }

        setActive(!isActive);
    }

    return (
        <>
            <div className={cn('social', className, {
                social_active: isActive,
            })}
            >
                <button className="social__toggle" type="button" onClick={toggleSharing}>
                    {isActive
                        ? <ArrowSvg className="social__toggle-icon-arrow" />
                        : <ShareSvg className="social__toggle-icon-share" /> }
                </button>
                <div className="social__container">
                    { buttons.map((button) => (
                        <button
                            key={button.type}
                            className="social__button"
                            data-type={button.type}
                            onClick={share}
                            type="button"
                        >
                            {button.icon()}
                        </button>
                    )) }
                    <button
                        className="social__button"
                        onClick={window.isGamecenter ? copyLinkGC : copyLink}
                        type="button"
                    >
                        <LinkSvg />
                    </button>
                </div>
            </div>
            <Snackbar label={snackLabel} setLabel={setSnackLabel} closeTimer={2000} />
        </>
    );
};

export default Social;
