export function JSONP(reqUrl, onSuccess, onError): void {
    window.CBRegistry = window.CBRegistry || {};

    let isSuccessful = false;

    const callbackName = `cb${String(Math.random()).slice(-6)}`;

    let url = reqUrl;
    url += url.includes('?') ? '&' : '?';
    url += `callback=window.CBRegistry.${callbackName}`;

    window.CBRegistry[callbackName] = (data): void => {
        isSuccessful = true;
        delete window.CBRegistry[callbackName];
        onSuccess(data);
    };

    function checkCallback(): void {
        if (isSuccessful) {
            return;
        }

        delete window.CBRegistry[callbackName];
        onError(url);
    }

    const script = document.createElement('script');

    script.onreadystatechange = (): void => {
        if (this.readyState === 'complete' || this.readyState === 'loaded') {
            this.onreadystatechange = null;
            setTimeout(checkCallback, 0);
        }
    };

    script.onerror = checkCallback;
    script.onload = checkCallback;
    script.src = url;

    document.body.appendChild(script);
}

export function runScript(id): void {
    const bannerNode = document.getElementById(id);

    if (!bannerNode) {
        return;
    }

    const scripts = bannerNode.getElementsByTagName('SCRIPT');

    if (!scripts || !scripts.length) {
        return;
    }

    for (let i = 0; i < scripts.length; i++) {
        if ((!scripts[i].src || !scripts[i].src.length) && scripts[i]) {
            const scriptTag = document.createElement('script');
            scriptTag.innerHTML = scripts[i].innerHTML;

            document.head.appendChild(scriptTag);
        }
    }
}
