import React, { useContext } from 'react';

import cn from 'classnames';

import GameContext from 'contexts/game';

import GameButtons from 'molecules/GameButtons';

import 'scss/skin/download-aside.scss';

import type { FC } from 'react';

interface Props {
    className?: string;
    isDark?: boolean;
    isSmall?: boolean;
    disabledButtons: string[];
}

const DownloadAside: FC<Props> = ({
    className,
    isDark,
    isSmall,
    disabledButtons,
}) => {
    const gameData = useContext(GameContext);

    if (!gameData) {
        return null;
    }

    return (
        <section
            className={cn(
                'download-aside',
                {
                    [className]: className,
                    'download-aside_dark': isDark,
                    'download-aside_small': isSmall,
                },
            )}
        >
            <GameButtons
                light={isDark}
                buttons={gameData?.game_urls}
                disabledButtons={disabledButtons}
            />
        </section>
    );
};

export default DownloadAside;
