import React from 'react';

import cn from 'classnames';

import 'scss/skeletons/feed-story.scss';

const Skeleton: React.FC = ({
    isWide, isMini,
}) => (
    <div className={cn(
        'feed-story-skeleton',
        {
            'feed-story-skeleton_wide': isWide,
            'feed-story-skeleton_mini': isMini,
        },
    )}
    >
        <div className="feed-story-skeleton__picture" />
        <div className="skeleton__text_sm" />
        <div className="skeleton__text_lg" />
        <div className="skeleton__text_md" />
    </div>
);

export default Skeleton;
