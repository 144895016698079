/* eslint-disable max-len */

export const singular: Record<string, Record<string, string>> = {
    ru: {
        news: 'Новость',
        cybersport: 'Киберспорт',
        gof: 'Игры Будущего',
        feat: 'Статья',
        'vkp-feat': 'Статья',
        review: 'Обзор',
        preview: 'Превью',
        secret: 'Секрет',
        'vkp-secret': 'Руководство',
        hard: 'Железо и софт',
        industry: 'Индустрия',
        lifestyle: 'Лайфстайл',
        video: 'Трейлер',
        files: 'Мод',
        file: 'Мод',
        compilation: 'Подборка',
    },
    en: {
        news: 'News',
        cybersport: 'eSports',
        gof: 'Games of Future',
        feat: 'Article',
        'vkp-feat': 'Article',
        review: 'Review',
        preview: 'Preview',
        secret: 'Secret',
        'vkp-secret': 'Secret',
        hard: 'Hard and Soft',
        industry: 'Industry',
        lifestyle: 'Lifestyle',
        video: 'Trailer',
        files: 'Mod',
        file: 'Mod',
        compilation: 'Compilation',
    },
};

export const plural: Record<string, Record<string, string>> = {
    ru: {
        media: 'Медиа',
        news: 'Новости',
        cybersport: 'Киберспорт',
        gof: 'Игры Будущего',
        articles: 'Статьи',
        'articles/feat': 'Лонгриды',
        'articles/vkp-feat': 'Статьи',
        'articles/review': 'Обзоры',
        'articles/preview': 'Превью',
        'articles/secret': 'Руководства',
        'articles/vkp-secret': 'Руководства',
        'articles/hard': 'Железо и софт',
        'articles/file': 'Моды',
        feat: 'Лонгриды',
        review: 'Обзоры',
        preview: 'Превью',
        secret: 'Руководства',
        hard: 'Железо и софт',
        industry: 'Игровая индустрия',
        'industry/news': 'Новости игровой индустрии',
        'industry/articles': 'Статьи игровой индустрии',
        lifestyle: 'Лайфстайл',
        'lifestyle/news': 'Новости лайфстайл',
        'lifestyle/articles': 'Статьи лайфстайл',
        video: 'Трейлеры',
        image: 'Галерея',
        files: 'Файлы',
        file: 'Моды',
        games: 'Игры',
        best: 'Золотой фонд',
        best_of_all_times: 'Лучшие всех времен',
        'games/calendar': 'Календарь релизов',
        'games/popular': 'Популярные игры',
        'games/compilation': 'Подборки игр',
        'games/best': 'Лучшие',
        requirements: 'Системные требования',
        'vkp-home': 'Все материалы',
        'vkp-news': 'Новости',
        ugc: 'Мои материалы',
        'ugc/feat': 'UGC',
    },
    en: {
        media: 'Media',
        news: 'News',
        cybersport: 'eSports',
        gof: 'Games of Future',
        articles: 'Articles',
        'articles/feat': 'Articles',
        'articles/vkp-feat': 'Articles',
        'articles/review': 'Reviews',
        'articles/preview': 'Previews',
        'articles/secret': 'Secrets',
        'articles/vkp-secret': 'Secrets',
        'articles/hard': 'Hard and Soft',
        'articles/file': 'Mods',
        industry: 'Gaming Industry',
        'industry/news': 'Gaming Industry News',
        'industry/articles': 'Gaming Industry Articles',
        files: 'Files',
        file: 'Mods',
        feat: 'Articles',
        review: 'Reviews',
        preview: 'Previews',
        secret: 'Secrets',
        hard: 'Hard and Soft',
        'vkp-home': 'All Stories',
        'vkp-news': 'Gaming News',
    },
};

export const commentsRest = {
    details: 'threads/details/',
    list: 'threads/comments/',
    thread: 'threads/create/',
    vote: 'comments/vote/',
    create: 'comments/create/',
    delete: 'comments/delete/',
    update: 'comments/update/',
};

const articleAliases = ['feat', 'vkp-feat', 'review', 'preview', 'secret', 'vkp-secret', 'hard', 'video', 'file'];

export function isArticleAlias(alias: string): boolean {
    return articleAliases.includes(alias);
}

export const releaseRegions = {
    wo: ' в мире',
    ru: ' в России',
    eu: ' в Европе',
    us: ' в США',
    jp: ' в Японии',
    kr: ' в Южной Корее',
};

export const metaData = {
    home: {
        title: 'Всё об играх',
        description: 'Компьютерные игры, мобильные игры, прохождение игр, коды, читы, трейнеры, обзоры, видео игр',
    },
    'vkp-home': {
        title: 'Медиа',
        description: 'Новости компьютерных игр, мобильных игр и киберспорта',
    },
    news: {
        title: 'Новости игр, анонсы, даты выхода, системные требования',
        description: 'Новости компьютерных и мобильных игр, анонсы, даты выхода, системные требования',
    },
    'vkp-news': {
        title: 'Новости игр, анонсы, даты выхода, системные требования',
        description: 'Новости компьютерных и мобильных игр, анонсы, даты выхода, системные требования',
    },
    cybersport: {
        title: 'Киберспортивные новости',
        description: 'Киберспортивные новости и анонсы',
    },
    gof: {
        title: 'Игры Будущего GoF.VKPLAY',
        description: 'Новый формат фиджитал соревнований по 16-ти инновационным дисциплинам. Участвуй в турнирах, смотри трансляции, будь в курсе новостей на GoF.VKPLAY!',
    },
    articles: {
        title: 'Статьи',
        description: 'Статьи, фичеры, репортажи о компьютерных и мобильных играх',
    },
    'articles/feat': {
        title: 'Лонгриды',
        description: 'Статьи, фичеры, репортажи о компьютерных и мобильных играх',
    },
    'articles/vkp-feat': {
        title: 'Статьи',
        description: 'Статьи, фичеры, репортажи о компьютерных и мобильных играх',
    },
    'articles/review': {
        title: 'Обзоры игр',
        description: 'Обзоры компьютерных и мобильных игр',
    },
    'articles/preview': {
        title: 'Превью',
        description: 'Статьи о компьютерных и мобильных играх, находящихся в разработке',
    },
    'articles/secret': {
        title: 'Секреты, коды, прохождения игр, трейнеры',
        description: 'Секреты, коды, читы, прохождения игр, советы, консольные команды, файлы для популярных игр',
    },
    'articles/vkp-secret': {
        title: 'Руководства, секреты, коды, прохождения игр, трейнеры',
        description: 'Руководства, секреты, коды, читы, прохождения игр, советы, консольные команды, файлы для популярных игр',
    },
    'articles/hard': {
        title: 'Железо и софт',
        description: 'Обзоры и тесты компьютеров, приставок, смартфонов, планшетов, актуальные конфигурации, полезный софт',
    },
    'articles/file': {
        title: 'Моды для компьютерных игр',
        description: 'Файлы, трейнеры, моды, читы для компьютерных игр',
    },
    'ugc/feat': {
        title: 'UGC',
        description: 'UGC тексты',
    },
    industry: {
        title: 'Новости и статьи игровой индустрии',
        description: 'Новости игровой индустрии 2022. Самые свежие статьи и новости мира игровой индустрии',
    },
    'industry/news': {
        title: 'Новости игровой индустрии',
        description: 'Новости игровой индустрии 2022. Самые свежие новости мира игровой индустрии',
    },
    'industry/articles': {
        title: 'Cтатьи игровой индустрии',
        description: 'Статьи игровой индустрии 2022. Самые свежие статьи мира игровой индустрии',
    },
    lifestyle: {
        title: 'Новости и статьи лайфстайл',
        description: 'Новости лайфстайл 2022. Самые свежие статьи и новости лайфстайл',
    },
    'lifestyle/news': {
        title: 'Новости лайфстайл',
        description: 'Новости лайфстайл 2022. Самые свежие новости лайфстайл',
    },
    'lifestyle/articles': {
        title: 'Cтатьи лайфстайл',
        description: 'Статьи лайфстайл 2022. Самые свежие статьи лайфстайл',
    },
    best: {
        title: 'Золотой фонд',
        description: 'Лучшие статьи и видео на PLAYER ONE',
    },
    'games/popular': {
        title: 'Популярные игры для PC, приставок и смартфонов',
        description: 'Популярные игры для компьютеров, игровых консолей, смартфонов, планшетов',
    },
    'games/calendar': {
        title: 'Календарь релизов компьютерных и мобильных игр',
        description: 'Даты выхода игр, ближайшие релизы',
    },
    'games/compilation': {
        title: 'Подборки игр для PC, приставок и смартфонов',
        description: 'Тематические подборки компьютерных и мобильных игр',
    },
    'games/best': {
        title: 'Лучшие игры для PC, приставок и смартфонов',
        description: 'Лучшие игры для компьютеров, игровых консолей, смартфонов, планшетов',
    },
    video: {
        title: 'Видео компьютерных игр, трейлеры, геймплей',
        description: 'Видео компьютерных игр, трейлеры, трансляции, видео геймплея',
    },
    files: {
        label: 'Файлы для компьютерных игр',
        description: 'Файлы, трейнеры, моды, читы для компьютерных игр',
    },
    file: {
        title: 'Моды для компьютерных игр',
        description: 'Файлы, трейнеры, моды, читы для компьютерных игр',
    },
    game: {
        title: '— гайды, новости, статьи,  обзоры, трейлеры, секреты',
        news: {
            title: 'Новости',
            subTitle: '— анонсы и события',
            description: 'анонсы, обзоры, отзывы и последние события в игре',
        },
        articles: {
            title: 'Статьи',
            subTitle: '',
            description: 'фичеры, репортажи, превью, обзоры и свежие статьи по игре',
        },
        review: {
            title: 'Обзоры',
            subTitle: '',
            description: 'фичеры, репортажи, превью, обзоры и свежие статьи по игре',
        },
        preview: {
            title: 'Превью',
            subTitle: '',
            description: 'фичеры, репортажи, превью, обзоры и свежие статьи по игре',
        },
        secret: {
            title: 'Секреты',
            subTitle: '— читы, прохождения',
            description: 'чит коды и советы по прохождению игры',
        },
        image: {
            title: 'Скриншоты',
            subTitle: '— картинки, арты, обои',
            description: 'Изображения, скриншоты, арты, обои игры',
        },
        video: {
            title: 'Трейлеры',
            subTitle: '— видео геймплея и трансляции',
            description: 'видео по игре, геймплейные трейлеры и трансляции',
        },
        requirements: {
            title: 'Системные требования',
            subTitle: '— минимальные и рекомендуемые',
            description: 'требуемые процессор, видеокарта, оперативная память и необходимое место на диске',
        },
        files: {
            title: 'Файлы',
            subTitle: '— моды, трейнеры, скины, читы',
            description: 'Файлы, трейнеры, моды, чит коды для компьютерных игр',
        },
    },
    special: {
        title: 'Спецпроекты и партнерские материалы',
        description: 'Спецпроекты, партнерские материалы, медиакит',
    },
};

export const gameSpecialPages = ['calendar', 'compilation', 'best'];

export const months = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
];

export const monthsPrepositional = [
    'январе',
    'феврале',
    'марте',
    'апреле',
    'мае',
    'июне',
    'июле',
    'августе',
    'сентябре',
    'октябре',
    'ноябре',
    'декабре',
];

export const quarters = [
    'в I квартале',
    'во II квартале',
    'в III квартале',
    'в IV квартале',
];

export const commentsOrder = {
    asc: 'старые',
    desc: 'новые',
    best: 'лучшие',
};

export const searchAccusative = {
    feat: 'статей',
    'vkp-feat': 'статей',
    game: 'игр',
    hard: 'железа и софта',
    news: 'новостей',
    'vkp-news': 'новостей игр',
    cybersport: 'киберспорта',
    gof: 'игр будущего',
    play_game: 'игр платформы',
    preview: 'превью',
    review: 'обзоров',
    secret: 'секретов',
    'vkp-secret': 'руководств',
    video: 'видео',
};

export const gameTabs = ['game', 'play_game'];

export const searchTabs = {
    play_game: {
        label: 'Игры платформы',
        alias: 'play_game',
    },
    game: {
        label: 'Справочник игр',
        alias: 'game',
    },
    news: {
        label: 'Новости',
        alias: 'news',
    },
    secret: {
        label: 'Секреты',
        alias: 'secret',
    },
    review: {
        label: 'Обзоры',
        alias: 'review',
    },
    preview: {
        label: 'Превью',
        alias: 'preview',
    },
    feat: {
        label: 'Статьи',
        alias: 'feat',
    },
    hard: {
        label: 'Железо и софт',
        alias: 'hard',
    },
    video: {
        label: 'Видео',
        alias: 'video',
    },
    requirements: {
        label: 'Системные требования',
        alias: 'requirements',
    },
};

type GetGameTabsReturn = Record<string, {
    label: string;
    link: string;
    labelButton?: string;
    isExact?: boolean;
}>;
export function getGameTabs(slug: string, fixedCounters: any): GetGameTabsReturn {
    return {
        main: {
            label: 'Все',
            link: `/games/${slug}/`,
            isExact: true,
        },
        news: {
            label: 'Новости',
            link: fixedCounters.news ? `/games/${slug}/news/` : null,
        },
        articles: {
            label: 'Статьи',
            link: fixedCounters.feat ? `/games/${slug}/articles/` : null,
        },
        review: {
            label: 'Обзоры',
            link: fixedCounters.review ? `/games/${slug}/review/` : null,
        },
        preview: {
            label: 'Превью',
            link: fixedCounters.preview ? `/games/${slug}/preview/` : null,
        },
        secret: {
            label: 'Секреты',
            link: fixedCounters.secret ? `/games/${slug}/secret/` : null,
        },
        image: {
            label: 'Галерея',
            link: fixedCounters.image ? `/games/${slug}/image/` : null,
        },
        video: {
            label: 'Трейлеры',
            link: fixedCounters.video ? `/games/${slug}/video/` : null,
        },
        files: {
            label: 'Файлы',
            link: fixedCounters.files ? `/games/${slug}/files/` : null,
        },
        file: {
            label: 'Моды',
            link: fixedCounters.file ? `/articles/file/?game_slug=${slug}` : null,
        },
        requirements: {
            label: 'Системные требования',
            labelButton: 'Требования',
            link: fixedCounters.requirements ? `/games/${slug}/requirements/` : null,
        },
    };
}

export const defaultCover = {
    news: 'https://games.mail.ru/hotbox/content_files/media/1643975555_green-blue.jpg',
    feat: 'https://games.mail.ru/hotbox/content_files/media/1643975559_orange-purple.jpg',
    'vkp-feat': 'https://games.mail.ru/hotbox/content_files/media/1643975559_orange-purple.jpg',
    review: 'https://games.mail.ru/hotbox/content_files/media/1643975562_pink-yellow.jpg',
    preview: 'https://games.mail.ru/hotbox/content_files/media/1643975579_white-pink.jpg',
    secret: 'https://games.mail.ru/hotbox/content_files/media/1643975566_purple-white.jpg',
    'vkp-secret': 'https://games.mail.ru/hotbox/content_files/media/1643975566_purple-white.jpg',
    hard: 'https://games.mail.ru/hotbox/content_files/media/1643975570_red-black.jpg',
    files: 'https://games.mail.ru/hotbox/content_files/media/1643975555_green-blue.jpg',
    video: 'https://games.mail.ru/hotbox/content_files/media/1643975562_pink-yellow.jpg',
    ugc: 'https://vkplay.ru/hotbox/content_files/media/b28a0ac9-d282-488c-9410-fbd618e401a5.png',
};

export const platforms = {
    amiga: 'Amiga',
    amiga_cd32: 'Amiga CD32',
    amstrad_cpc: 'Amstrad CPC',
    android: 'Android',
    apple_ii: 'Apple II',
    arcade: 'Arcade',
    arcadia_2001: 'Arcadia 2001',
    atari_2600: 'Atari 2600',
    atari_jaguar: 'Atari Jaguar',
    atari_st: 'Atari ST',
    blackberry: 'Blackberry',
    brauzernaja: 'Браузерная игра',
    cd_i: 'CD-i',
    commodore_64: 'Commodore 64',
    doja: 'DoJa',
    dreamcast: 'Dreamcast',
    drugie_platformy: 'Другие платформы',
    dsiware: 'DSiWare',
    famicom_ds: 'Famicom DS',
    fire_tv: 'Fire TV',
    game_boy_advance: 'Game Boy Advance',
    game_boy_color: 'Game Boy Color',
    gameboy: 'GameBoy',
    gamecube: 'GameCube',
    gba: 'GBA',
    gear_vr: 'Gear VR',
    google_stadia: 'Google Stadia',
    hp_2100: 'HP 2100',
    'intellivision-amico': 'Intellivision Amico',
    ios: 'iOS',
    ipad: 'iPad',
    iphone: 'iPhone',
    konsoli: 'Консоли',
    linux: 'Linux',
    mac: 'ПК (macOS)',
    magic_leap_one: 'Magic Leap One',
    mobile: 'Mobile',
    msx: 'MSX',
    nes: 'NES',
    nintendo_3ds: 'Nintendo 3DS',
    nintendo_64: 'Nintendo 64',
    nintendo_ds: 'Nintendo DS',
    nintendo_gamecube: 'Nintendo GameCube',
    nintendo_nx: 'Nintendo NX',
    nintendo_switch: 'Nintendo Switch',
    oculus_quest: 'Oculus Quest',
    ouya: 'Ouya',
    panasonic_3do: 'Panasonic 3DO',
    pc_windows: 'ПК (Windows)',
    playstation: 'PlayStation',
    'playstation-5': 'PlayStation 5',
    playstation_2: 'PlayStation 2',
    playstation_3: 'PlayStation 3',
    playstation_4: 'PlayStation 4',
    playstation_network: 'PlayStation Network',
    playstation_portable: 'PlayStation Portable',
    ps_vita: 'PS Vita',
    sega_32x: 'Sega 32X',
    sega_cd: 'SEGA CD',
    sega_game_gear: 'Sega Game Gear',
    sega_master_system: 'SEGA Master System',
    sega_mega_drive: 'Sega Mega Drive',
    sega_pico: 'SEGA Pico',
    sega_saturn: 'Sega Saturn',
    sharp_x68000: 'Sharp X68000',
    snes: 'SNES',
    snes_mini: 'SNES Mini',
    socialnaja: 'Социальная игра',
    steamos: 'SteamOS',
    studio_des_tenebres: 'Studio des Tenebres',
    ti_99_4a: 'TI-99/4A',
    turbografx_16: 'TurboGrafx-16',
    'valve-index': 'Valve Index',
    virtual_console: 'Virtual Console',
    wii: 'Wii',
    wii_u: 'Wii U',
    wiiware: 'WiiWare',
    windows_phone: 'Windows Phone',
    xbox: 'Xbox',
    'xbox-series-x': 'Xbox Series X',
    xbox_360: 'Xbox 360',
    xbox_live: 'Xbox Live',
    xbox_one: 'Xbox One',
    xbox_one_x: 'Xbox One X',
    zeebo: 'Zeebo',
    zx_spectrum: 'ZX Spectrum',
};

export const DEFAULT_HOT_EN_STORIES = [
    {
        name: 'The Worst Tournaments in Dota 2 History',
        picture: '/hotbox/content_files/news/2022/09/13/9f98bf424e6c4fb29fa3fefbf8c0b412.jpg',
        url: '/news/2022-09-16/the-worst-tournaments-in-dota-2-history/',
    },
    {
        name: '6 Tycoon Simulators for Everyone',
        picture: '/hotbox/content_files/news/2022/09/13/5f4fce8e06754f90a7bb1a94bdbd5f41.jpg',
        url: '/news/2022-09-16/6-tycoon-simulators-for-everyone/',
    },
    {
        name: 'The Most Successful Esports Academies and Their Best Graduates',
        picture: '/hotbox/content_files/news/2022/09/13/0039f1ccdcb0400daf5db5d84fae94fb.jpg',
        url: '/news/2022-09-16/the-most-successful-esports-academies-and-their-best-graduates/',
    },
    {
        name: 'Tasty Fishes and Where to Find Them: Fishing in MMO Games',
        picture: '/hotbox/content_files//Stories/2023/06/06/9a2eea1c386e47788b73a77bfdbb4b01.jpg',
        url: '/news/2022-09-16/tasty-fishes-and-where-to-find-them-fishing-in-mmo-games/',
    },
];
