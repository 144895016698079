import React from 'react';

import type { QuizResults } from 'entities/Quiz';
import type { FC } from 'react';

const QuizResult: FC<QuizResults> = ({ html_descr, title }) => (
    <div className="quiz-widget__results">
        <p className="quiz-widget__results-title">{title}</p>
        <div className="quiz-widget__results-description">
            <p dangerouslySetInnerHTML={{ __html: html_descr }} />
        </div>
    </div>
);

export default QuizResult;
