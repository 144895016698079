export const SIZE_QUERIES_MAP: Record<string, number[]> = {
    'min-width': [1200],
    'max-width': [1199],
};

export const IMAGE_FORMAT_MAP: Record<string, string> = {
    webp: 'webp',
};

export const IMAGE_TYPES = ['webp'] as const;
