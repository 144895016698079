import React from 'react';

import MediaSource from 'atoms/Picture/components/MediaSource';
import { SIZE_QUERIES_MAP } from 'atoms/Picture/constants';

import type { AvailablePictureTypes, BaseProps } from 'atoms/Picture/types';
import type { FC } from 'react';

interface Props extends Pick<BaseProps, 'src' | 'width' | 'height' | 'mobHeight' | 'mobWidth' | 'quality' | 'noGif'> {
    types: readonly AvailablePictureTypes[];
}

const Sources: FC<Props> = ({
    src,
    types,
    width,
    height,
    mobWidth,
    mobHeight,
    quality,
    noGif,
}) => {
    if (!mobWidth && !mobHeight) {
        return (
            <>
                {types.map((type) => (
                    <MediaSource
                        key={type}
                        src={src}
                        type={type}
                        size={0}
                        width={width}
                        height={height}
                        quality={quality}
                        noGif={noGif}
                    />
                ))}
            </>
        );
    }

    return (
        <>
            {types.map((type) => (Object.entries(SIZE_QUERIES_MAP).map(
                ([query, values]) => values.map(
                    (size) => (
                        <MediaSource
                            key={`${type}-${size}`}
                            src={src}
                            type={type}
                            size={size}
                            mediaQuery={query}
                            mobWidth={mobWidth}
                            mobHeight={mobHeight}
                            width={width}
                            height={height}
                            quality={quality}
                            noGif={noGif}
                        />
                    ),
                ),
            )))}
        </>
    );
};

export default Sources;
