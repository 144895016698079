export default function getDisplayMode(): string {
    let displayMode = 'browser';
    const mqStandAlone = '(display-mode: standalone)';

    if (navigator.standalone || window.matchMedia(mqStandAlone).matches) {
        displayMode = 'PWA';
    }

    return displayMode;
}
