import React, { useState, useEffect, Suspense } from 'react';

import cn from 'classnames';
import { useHistory } from 'react-router-dom';

import { metaData, plural } from 'aliases';

import NotFound from 'pages/NotFound';

import Head from 'atoms/Head';
import Main from 'atoms/Main';

import Breadcrumbs from 'molecules/Breadcrumbs';
import Tabs from 'molecules/Tabs';

import HomeAside from 'organisms/HomeAside';

import usePageClassName from 'hooks/usePageClassName';

import 'scss/skin/games.scss';

import type { RouteComponentProps } from 'react-router-dom';

const GamesPopular = React.lazy(() => import('organisms/GamesPopular'));
const GamesCalendar = React.lazy(() => import('organisms/GamesCalendar'));
const GamesCompilations = React.lazy(() => import('organisms/GamesCompilations'));
const GamesBest = React.lazy(() => import('organisms/GamesBest'));

const gameTabs = [
    {
        label: 'Популярные',
        link: '/games/',
        isExact: true,
    },
    {
        label: 'Календарь',
        link: '/games/calendar/',
    },
    {
        label: 'Подборки',
        link: '/games/compilation/',
    },
    {
        label: 'Лучшие',
        link: '/games/best/',
    },
];

const Games: React.FC<RouteComponentProps> = ({
    alias, slug, majorType,
    location: { search },
}) => {
    const history = useHistory();

    usePageClassName('games-page');

    const [params, setParams] = useState(null);
    const [meta, setMeta] = useState(null);
    const [noData, setNoData] = useState(false);
    const [currentCompilation, setCurrentCompilation] = useState(null);

    useEffect(() => {
        const searchQuery = new URLSearchParams(search);
        setParams({
            page: searchQuery.get('page') || 1,
            filter: searchQuery.get('filter'),
            type: alias || 'popular',
            compoundType: majorType ? `${majorType}/${alias || 'popular'}` : null,
            slug,
        });
    }, [search, slug, alias]);

    useEffect(() => {
        if (!params) {
            return;
        }

        setMeta({
            title: metaData[params.compoundType || params.type].title,
            description: metaData[params.compoundType || params.type].description,
            canonical: window.location.href,
            titlePostfix: params && +params.page > 1 ? ` | Страница ${params.page}` : '',
        });
    }, [params]);

    function handleNoData(): void {
        setNoData(true);
    }

    function handleCompChange(compName): void {
        setCurrentCompilation(compName);
    }

    if (!params) {
        return null;
    }

    if (noData) {
        return <NotFound />;
    }

    const {
        page: pPage,
        filter: pFilter,
        slug: pSlug,
        type: pType,
        compoundType,
    } = params;

    return (
        <>
            {meta && <Head {...meta} />}
            <Breadcrumbs alias={pType} majorType="games" name={pSlug} dark />
            <div className="feed__header">
                <h1 id="feed__title" className={cn('feed__title', { feed__title_small: currentCompilation })}>
                    {pSlug ? currentCompilation : plural.ru[compoundType || pType]?.split(' ')[0]}
                </h1>
                <Tabs
                    className="feed__tabs"
                    tabs={gameTabs}
                    history={history}
                />
            </div>
            <Main>
                <div>
                    <Suspense fallback={null}>
                        { pType === 'popular' && (
                            <GamesPopular
                                initialPage={pPage}
                                initialFilter={pFilter}
                                initialMeta={meta}
                            />
                        )}
                        { pType === 'calendar' && (
                            <GamesCalendar
                                initialDate={pSlug}
                                initialMeta={meta}
                                onNoData={handleNoData}
                            />
                        )}
                        { pType === 'compilation' && (
                            <GamesCompilations
                                compilation={pSlug}
                                initialPage={pPage}
                                initialMeta={meta}
                                onNoData={handleNoData}
                                onCurrentCompChange={handleCompChange}
                            />
                        )}
                        { pType === 'best' && (
                            <GamesBest
                                initialPage={pPage}
                                initialFilter={pFilter}
                                initialMeta={meta}
                            />
                        )}
                    </Suspense>
                </div>
            </Main>
            <HomeAside />
        </>
    );
};

export default Games;
