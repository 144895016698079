import React, {
    useState, useEffect,
} from 'react';

import cn from 'classnames';

const labels = {
    cpu: 'ЦП',
    ram: 'Оперативная память',
    gpu: 'Видеокарта',
    hdd: 'Место на диске',
};

const GameRequirements: React.FC = ({ requirements, onLoad }) => {
    const [data, setData] = useState(null);
    const [activeReq, setActiveReq] = useState('min');
    const [isFallback, setFallback] = useState(false);
    const [isLoaded, setLoaded] = useState(false);

    useEffect(() => {
        if (!requirements.str || requirements.str.length < 4) {
            setFallback(true);

            return;
        }

        setData(JSON.parse(requirements.str));
    }, [requirements.str]);

    function handleLoaded(): void {
        if (!isLoaded && typeof onLoad === 'function') {
            setLoaded(true);
            onLoad();
        }
    }

    function handleReqSet(e): void {
        const { req } = e.currentTarget.dataset;

        setActiveReq(req);
    }

    function renderMinHeader(): React.ReactNode {
        return (
            <div className={cn('game-section__column', { 'game-section__column_active': activeReq === 'min' })}>
                <button
                    className="game-section__subtitle game-section__subtitle_button"
                    data-req="min"
                    onClick={handleReqSet}
                    type="button"
                >
                    Минимальные
                </button>
            </div>
        );
    }

    function renderRecHeader(): React.ReactNode {
        return (
            <div className={cn('game-section__column', { 'game-section__column_active': activeReq === 'rec' })}>
                <button
                    className="game-section__subtitle game-section__subtitle_button"
                    data-req="rec"
                    onClick={handleReqSet}
                    type="button"
                >
                    Рекомендуемые
                </button>
            </div>
        );
    }

    function renderHeader(withMin, withRec): React.ReactNode {
        return (
            <div className="game-section__row game-section__row_header">
                {withMin && renderMinHeader()}
                {withRec && renderRecHeader()}
            </div>
        );
    }

    function renderMinBody(children): React.ReactNode {
        return (
            <div className={cn('game-section__column', { 'game-section__column_active': activeReq === 'min' })}>
                <p className="game-section__info game-section__info_fallback">{children}</p>
            </div>
        );
    }

    function renderRecBody(children): React.ReactNode {
        return (
            <div className={cn('game-section__column', { 'game-section__column_active': activeReq === 'rec' })}>
                <p className="game-section__info game-section__info_fallback">{children}</p>
            </div>
        );
    }

    function renderFallback(): React.ReactNode {
        const withMin = requirements.fallMin && requirements.fallMin.length > 1;
        const withRec = requirements.fallRec && requirements.fallRec.length > 1;

        if (!withMin && !withRec) {
            return null;
        }

        return (
            <>
                {renderHeader(withMin, withRec)}
                <div className="game-section__row game-section__row_fallback">
                    {withMin && renderMinBody(requirements.fallMin)}
                    {withRec && renderRecBody(requirements.fallRec)}
                    {handleLoaded()}
                </div>
            </>
        );
    }

    function renderReqs(): React.ReactNode {
        if (!data) {
            return null;
        }

        const withMin = data.min && Object.keys(data.min).length;
        const withRec = data.rec && Object.keys(data.rec).length;

        if (!withMin && !withRec) {
            return null;
        }

        const min = withMin && Object.keys(data.min).map((key) => (
            <React.Fragment key={`min-${key}`}>
                <span className="game-section__subtitle game-section__subtitle_inner">{labels[key]}</span>
                <span className="game-section__req-text">{data.min[key]}</span>
            </React.Fragment>
        ));

        const rec = withRec && Object.keys(data.rec).map((key) => (
            <React.Fragment key={`rec-${key}`}>
                <span
                    key={`rec-sub-${key}`}
                    className="game-section__subtitle game-section__subtitle_inner"
                >
                    {labels[key]}
                </span>
                <span key={`rec-text-${key}`} className="game-section__req-text">{data.rec[key]}</span>
            </React.Fragment>
        ));

        return (
            <>
                {renderHeader(withMin, withRec)}
                <div className="game-section__row">
                    {withMin && renderMinBody(min)}
                    {withRec && renderRecBody(rec)}
                    {handleLoaded()}
                </div>
            </>
        );
    }

    return isFallback ? renderFallback() : renderReqs();
};

export default GameRequirements;
