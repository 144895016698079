export const EDITORIAL_BANNER_PLACE_STRING = 'bannerPlace';

export const MESSAGES = {
    ru: {
        moar: 'Показать ещё',
        nothing: 'Ничего не найдено 🥲',
    },
    en: {
        moar: 'Show more',
        nothing: 'Nothing found 🥲',
    },
};
