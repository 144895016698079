import React, {
    createContext, useContext, useMemo, useState,
} from 'react';

const UpdateCounterContext = createContext();

function UpdateCounterProvider({ children }) {
    const [updateCounter, setUpdateCounter] = useState(0);

    function incrementUpdateCounter() {
        setUpdateCounter(updateCounter + 1);
    }

    // eslint-disable-next-line max-len
    const value = useMemo(() => ({ updateCounter, incrementUpdateCounter }), [updateCounter, setUpdateCounter, incrementUpdateCounter]);

    return (
        <UpdateCounterContext.Provider value={value}>
            {children}
        </UpdateCounterContext.Provider>
    );
}

function useUpdateCounter() {
    const context = useContext(UpdateCounterContext);

    if (context === undefined) {
        throw new Error('useUpdateCounter must be used within a UpdateCounterProvider');
    }

    return context;
}

export { UpdateCounterProvider, useUpdateCounter };
