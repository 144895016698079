import React from 'react';

import Widget from '../../Widget';

const Incarnate: React.FC = ({
    data,
}) => (
    <Widget
        id="widget-image"
        {...data}
        type="image"
    />
);

export default Incarnate;
