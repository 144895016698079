import React, { useEffect } from 'react';

import Head from 'atoms/Head';

import { messages } from './constants';

import { ReactComponent as PlugSvg } from 'img/404-plug.svg';

import 'scss/skin/not-found.scss';

const NotFound: React.FC = () => {
    let pageNode = null;
    let headerNode = null;

    function setBasicStyling(node): void {
        if (!node) {
            return;
        }

        pageNode = node.parentNode;
        headerNode = pageNode.previousSibling;

        headerNode?.classList.add('header_nav-detached');
        pageNode?.classList.add('page_no-background');
    }

    useEffect(() => () => {
        headerNode?.classList.remove('header_nav-detached');
        pageNode?.classList.remove('page_no-background');
    }, []);

    return (
        <div className="page-404" ref={setBasicStyling}>
            <Head
                title={`404 — ${messages.ru.title}`}
                httpEquiv="404 Not found"
            />
            <h1 className="page-404__title">
                {messages.ru.title}
                .
                <br />
                {messages.ru.error}
                .
            </h1>
            <p
                className="page-404__subtitle"
            >
                {messages.ru.text}
            </p>
            <PlugSvg className="page-404__plug" />
            <div id="prerenderAnchor" />
        </div>
    );
};

export default React.memo(NotFound);
