import React, { useEffect, useContext } from 'react';

import AuthContext from 'contexts/auth';

const imgStyle = {
    width: 0, height: 0, position: 'absolute', visibility: 'hidden',
};

const idByCounter = {
    p1: '1585480',
    common: '3254134',
    'common-2': '3247783',
    nested: '3252497',
    nestedProxy: '3385732',
};

export function sendPageViewProxy({ counter, options }) {
    const tmrVkp = window._tmr_vkp_gen || (window._tmr_vkp_gen = []);

    tmrVkp.push(options ? {
        ...options,
        id: idByCounter[counter],
        type: 'pageView',
        timespent: true,
    } : {
        id: idByCounter[counter],
        type: 'pageView',
        start: (new Date()).getTime(),
        timespent: true,
    });
}

export function sendPageView({ counter, options }) {
    const tmr = window._tmr || (window._tmr = []);

    tmr.push(options ? {
        ...options,
        id: idByCounter[counter],
        type: 'pageView',
        timespent: true,
    } : {
        id: idByCounter[counter],
        type: 'pageView',
        start: (new Date()).getTime(),
        timespent: true,
    });
}

export default function TopMailCounter({ counter }) {
    const { user } = useContext(AuthContext);

    useEffect(() => {
        initTMR();
    }, []);

    useEffect(() => {
        if (user.user_id) {
            const tmr = window._tmr || (window._tmr = []);
            tmr.push({ id: idByCounter[counter], type: 'setUserID', userid: user.user_id });
        }
    }, [user]);

    function initTMR() {
        sendPageView({
            counter: 'p1',
        });
    }

    return (
        <img
            src="https://rs.mail.ru/d270157.gif?rnd=101342108&ts=1574326520"
            style={imgStyle}
            alt=""
        />
    );
}
