import { useState, useEffect } from 'react';

export default function useScrolledState(): boolean {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        // window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });

        return (): void => window.removeEventListener('scroll', onScroll);
    }, []);

    function onScroll(): void {
        window.removeEventListener('scroll', onScroll);

        setTimeout(() => {
            setIsScrolled(true);
        }, 1000);
    }

    return isScrolled;
}
