import React, { useState, useEffect } from 'react';

import Button from 'atoms/Button';

import { ReactComponent as ArrowSVG } from 'img/paging-arrow.svg';

import 'scss/skin/paging.scss';

const Paging: React.FC = ({
    current, limit, totalItems, scrollTo,
}) => {
    const [lastPage, setLastPage] = useState(null);

    useEffect(() => {
        setLastPage(Math.ceil(totalItems / limit));
    }, [totalItems, limit]);

    function setHref(index, isAbsolute) {
        const { pathname, search } = document.location;
        const searchQuery = new URLSearchParams(search);

        searchQuery.set('page', index + (isAbsolute ? 0 : current));

        return `${pathname}?${searchQuery.toString()}`;
    }

    if (!totalItems || !lastPage) {
        return null;
    }

    return (
        <nav className="paging">
            <Button
                variant="primary-link"
                size="xsm"
                href={setHref(-1)}
                role="link"
                scrollTo={scrollTo}
                isDisabled={current === 1}
            >
                <ArrowSVG className="paging__button-arrow prev" />

            </Button>
            <Button
                className="paging__button"
                variant={current === 1 ? 'primary' : 'inverted-primary'}
                size="xsm"
                href={setHref(1, true)}
                role="link"
                scrollTo={scrollTo}
                label="1"
                noPointerEvents={current === 1}
            />
            {[...Array(3)].map((item, i) => (i + current !== 1) && (i + current <= lastPage) && (
                <Button
                    // eslint-disable-next-line react/no-array-index-key
                    key={i}
                    className="paging__button"
                    variant={i === 0 ? 'primary' : 'inverted-primary'}
                    size="xsm"
                    href={setHref(i)}
                    role="link"
                    scrollTo={scrollTo}
                    label={current + i}
                    noPointerEvents={i === 0}
                />
            ))}
            {current + 2 < lastPage && (
                <>
                    <span className="paging__dots">...</span>
                    <Button
                        className="paging__button"
                        variant="inverted-primary"
                        size="xsm"
                        href={setHref(lastPage, true)}
                        role="link"
                        scrollTo={scrollTo}
                        label={lastPage}
                    />
                </>
            )}
            <Button
                className="paging__button-next"
                variant="outline-primary"
                size="sm"
                href={setHref(1)}
                role="link"
                scrollTo={scrollTo}
                isDisabled={current === lastPage}
            >
                Дальше
                {' '}
                <ArrowSVG className="paging__button-arrow next" />

            </Button>
        </nav>
    );
};

export default Paging;
