type Data = Record<string, string | number | boolean | undefined | null>;

const removeEmptyParameters = (data: Data) => {
    const array = Object.entries(data);

    return array.reduce<Record<string, string>>((accumulator, [key, value]) => {
        if (value !== null && value !== undefined) {
            accumulator[key] = `${value}`;
        }

        return accumulator;
    }, {});
};

const serializeSearchParams = (data: Data) => {
    const preparedData = removeEmptyParameters(data);

    const urlSearchParams = new URLSearchParams(preparedData);

    return urlSearchParams.toString();
};

export default serializeSearchParams;
