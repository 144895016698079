import React from 'react';

import Incarnate from 'molecules/FeedImage/Incarnate';

const FeedImage: React.FC = (props) => {
    const { data } = props;

    if (!data) {
        return null;
    }

    return <Incarnate {...props} />;
};

export default FeedImage;
