import React from 'react';

import classNames from 'classnames';

import Picture from 'src/atoms/Picture';

import type { QuizCoverProps } from 'organisms/Quiz/types';
import type { FC } from 'react';

const BUTTON_LABEL = {
    start: 'Пройти викторину',
    questions: 'Следующий вопрос',
    result: '',
    'to-results': 'Узнать результат',
};

const QuizCover: FC<QuizCoverProps> = ({
    image,
    title,
    buttonState,
    isButtonHidden,
    onClick,
}) => (
    <div className={classNames('quiz-widget__cover', {
        'no-button': isButtonHidden,
        narrow: buttonState !== 'start',
    })}
    >
        {image && (
            <Picture
                classNameWrapper="quiz-widget__cover-image"
                src={image}
                alt={title}
                width={1096}
                height={515}
            />
        )}
        <div className="quiz-widget__cover-content">
            <button
                className="quiz-widget__cover-button"
                onClick={onClick}
                type="button"
            >
                {BUTTON_LABEL[buttonState]}
            </button>
        </div>
    </div>
);

export default QuizCover;
