import React, { useState, useEffect } from 'react';

import cn from 'classnames';

import { getDateFromISOWithOffset, getISOStringWithoutOffset } from 'utils/dates';
import ReactGa from 'utils/ReactGa';

import 'scss/skin/time.scss';

const msPerMinute = 60 * 1000;
const msPerHour = msPerMinute * 60;
const msPerDay = msPerHour * 24;

const dateTypesMap = ['month', 'month', 'quarter', 'year'];
const daiveMonthsMap = [
    'январе',
    'феврале',
    'марте',
    'апреле',
    'мае',
    'июне',
    'июле',
    'августе',
    'сентябре',
    'октябре',
    'ноябре',
    'декабре',
];

const messages = {
    ru: {
        at: 'в',
    },
    en: {
        at: 'at',
    },
};

const Time: React.FC = ({
    time, dark, small, relative,
    noExactTime, noYear,
    className, fullMonth, noFuture,
    dateType, isDative,
}) => {
    const [formattedTime, setTime] = useState<string>();

    useEffect(() => {
        if (!time) {
            return;
        }

        let dateObject = getDateFromISOWithOffset(time);

        if (Number.isNaN(dateObject.getDate())) { // invalid date
            ReactGa.event({
                category: 'Date error',
                action: 'Timezone exception',
                // eslint-disable-next-line max-len
                label: `RawTime: ${time}, DateObj: ${dateObject}, Path: ${window.location.pathname}`,
            });

            dateObject = new Date(time.replace(/-/g, '/'));

            if (Number.isNaN(dateObject.getDate())) {
                ReactGa.event({
                    category: 'Date error',
                    action: 'Replace exception',
                    // eslint-disable-next-line max-len
                    label: `RawTime: ${time}, DateObj: ${dateObject}, Path: ${window.location.pathname}`,
                });

                return;
            }
        }

        const elapsed = Date.now() - dateObject.getTime();

        let formatted = null;

        if (Intl && Intl.RelativeTimeFormat) {
            formatted = new Intl.RelativeTimeFormat('ru', { numeric: 'auto' });
        }

        if (noFuture && elapsed < msPerMinute) {
            setTime('Только что');

            return;
        }

        if (relative && formatted && elapsed < msPerHour) {
            const minutes = Math.round(elapsed / msPerMinute);
            formatted = formatted.format(-minutes, 'minutes');
        } else if (relative && formatted && elapsed < msPerDay * 2 && dateObject.getMonth() === new Date().getMonth()) {
            const days = dateObject.getDate() - new Date().getDate();
            // eslint-disable-next-line max-len
            const exactTime = dateObject.toLocaleTimeString('ru-ru', { hour: '2-digit', minute: '2-digit' });
            formatted = `${formatted.format(days, 'days')} ${messages.ru.at} ${exactTime}`;
        } else {
            // eslint-disable-next-line max-len
            const days = dateObject.toLocaleDateString('ru-ru', { day: 'numeric', month: fullMonth ? 'long' : 'short' });
            const month = dateObject.toLocaleString('ru-ru', { month: 'long' });
            const quarter = Math.floor((dateObject.getMonth() + 3) / 3);
            const year = dateObject.toLocaleDateString('ru-ru', { year: 'numeric' });
            const date = noYear ? days : `${days} ${year}`;

            if (dateTypesMap.includes(dateType)) {
                if (dateType === 'month') {
                    formatted = isDative
                        ? `${messages.ru.at} ${daiveMonthsMap[dateObject.getMonth()]} ${year}`
                        : `${month} ${year}`;
                } else if (dateType === 'quarter') {
                    formatted = isDative
                        ? `${messages.ru.at} ${quarter}-м квартале ${year}`
                        : `${quarter}-й квартал ${year}`;
                } else if (dateType === 'year') {
                    formatted = isDative ? `${messages.ru.at} ${year} году` : `${year} год`;
                }
            } else {
                // eslint-disable-next-line max-len
                const exactTime = dateObject.toLocaleTimeString('ru-ru', { hour: '2-digit', minute: '2-digit' });
                formatted = noExactTime ? date : `${date} ${messages.ru.at} ${exactTime}`;
            }
        }

        setTime(formatted);
    }, [relative, time, noExactTime, noYear]);

    return (
        <time
            className={cn(
                'time',
                className,
                {
                    time_dark: dark,
                    time_small: small,
                },
            )}
            dateTime={getISOStringWithoutOffset(time)}
        >
            {formattedTime || ''}
        </time>
    );
};

Time.defaultProps = {
    dateType: 'day',
};

export default Time;
