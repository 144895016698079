import { isNumber } from 'typeguards';

import { EDITORIAL_BANNER_PLACE_STRING } from 'organisms/Feed/constants';

const insertEditorialBannerPlaceToFeed = <T extends unknown[]>(array: T, editorialBannerPlace?: number) => {
    if (!isNumber(editorialBannerPlace)) {
        return {
            withBanner: false,
            feed: array,
        };
    }

    const arrayCopy = [...array] as T;

    arrayCopy.splice(editorialBannerPlace, 0, EDITORIAL_BANNER_PLACE_STRING);

    return {
        withBanner: true,
        feed: arrayCopy,
    };
};

export default insertEditorialBannerPlaceToFeed;
