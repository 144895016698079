import ReactGa from './ReactGa';

interface Params {
    action?: string;
    label?: string;
    additionalData?: string;
}

// Отправка дополнительных событий в счетчики на vkplay.ru
const sendExtraEvents = ({ action, label, additionalData }: Params): void => {
    const consentExist = window.__cmp
        && window.__cmp('consentStatus')?.consentExists
        && window.__cmp('consentStatus')?.userChoiceExists;

    const marketingCookie = window.__cmp
        && window.__cmp('getCMPData')?.purposeConsents.c52; // c52 marketing cookie purpose

    const excludeGA = ['pageview', 'scroll'];

    if (window.__cmp && (!consentExist || !marketingCookie)) {
        return;
    }

    const dashedLabel = label ? `_${label}` : '';

    // GA
    if (!excludeGA.includes(action)) {
        ReactGa.event({
            category: 'vkplay_events',
            action: `media_${action}${dashedLabel}`,
        });
    }

    // YM
    try {
        if (window.ym) {
            window.ym('params', {
                vkplay_events: {
                    vkplay_events: `media_${action}${dashedLabel}`,
                },
            });
        }
    } catch (error) {
        // ignore
    }

    // top.mail.ru
    const tmr = window._tmr || (window._tmr = []);
    const TMR_ID = '3247783';

    const goalParams = {
        category: 'media',
        action,
        ...((additionalData || label) && { label: additionalData || label }),
        url: window.location.href,
    };

    tmr.push({
        id: TMR_ID,
        type: 'reachGoal',
        goal: 'vkplay',
        params: goalParams,
    });

    console.warn('🚀 extra event sent:', action, label, additionalData);
};

export default sendExtraEvents;
