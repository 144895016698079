import React, { useMemo, useEffect } from 'react';

import ReactDOM from 'react-dom';

import 'scss/skin/snackbar.scss';

const modalRoot = document.getElementById('modal-root') || document.body;

const Snackbar: React.FC = ({
    label, setLabel, closeTimer,
}) => {
    useEffect(() => {
        if (!label) {
            return;
        }

        setTimeout(handleClose, closeTimer);
    }, [label]);

    function handleClose(): void {
        setLabel(null);
    }

    const snack = useMemo(() => (label ? (
        <div className="snackbar">
            {label}
            {/* <button className="snackbar__close" onClick={handleClose} type="button">
                <CrossSvg className="snackbar__close-icon" />
            </button> */}
        </div>
    ) : null), [label]);

    if (!label) {
        return null;
    }

    return ReactDOM.createPortal(snack, modalRoot);
};

Snackbar.defaultProps = {
    closeTimer: 5000,
};

export default Snackbar;
