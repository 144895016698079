export function setScriptTag({
    src, inlineScript, onload, data, async, prepend, id,
}): void {
    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.onload = onload;

    if (id) { // предотвращаем двойную вставку скрипта
        script.id = id;
        const prevScript = document.getElementById(id);

        if (prevScript) {
            return;
        }
    }

    if (src) {
        script.src = src;
    }

    if (async) {
        script.async = true;
    }

    if (data) {
        Object.keys(data).forEach((key) => {
            script.setAttribute([`data-${key}`], data[key]);
        });
    }

    if (inlineScript) {
        const textNode = document.createTextNode(inlineScript);
        script.appendChild(textNode);
    }

    if (prepend) {
        document.body.prepend(script);

        return;
    }

    document.body.appendChild(script);
}

export function removeScriptTag({ id }): void {
    const elem = document.getElementById(id);

    if (!elem || !elem.outerHTML) {
        return;
    }

    elem.outerHTML = '';
}
