import type { EditorialBannerFeedPlace } from 'entities/EditorialBanner';

export const IS_PRODUCTION = WEBPACK_APP_MODE === 'production';
export const LEELA_PATH = WEBPACK_LEELA_PATH;

export const LEAD_CONTENT_LENGTH = 200;

export const EDITORIAL_BANNER_PLACES: Record<string, EditorialBannerFeedPlace> = {
    home: { desktop: 0 },
    homeAlt: { mobile: 1 },
    articles: { desktop: 8, mobile: 1 },
    'vkp-news': { desktop: 8, mobile: 1 },
    'vkp-feat': { desktop: 8, mobile: 1 },
    'vkp-secret': { desktop: 8, mobile: 1 },
    cybersport: { desktop: 8, mobile: 1 },
    video: { desktop: 8, mobile: 1 },
    industry: { desktop: 8, mobile: 1 },
    file: { desktop: 12, mobile: 4 },
    games: { desktop: 9, mobile: 1 },
    gamesCompilations: { desktop: 7, mobile: 1 },
    gamesCompilation: { desktop: 6, mobile: 1 },
};
