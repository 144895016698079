import React, { useState, useEffect, useContext } from 'react';

import cn from 'classnames';

import MessagingContext from 'contexts/messaging';

import { ReactComponent as BellSvg } from 'icons/bell.svg';
import { ReactComponent as CrossSvg } from 'icons/cross-slim.svg';
import ReactGa from 'utils/ReactGa';

import 'scss/custom-push.scss';

interface Props {
    isStatic: boolean;
    isActive: boolean;
}

export default function CustomPush({ isStatic, isActive }): Props {
    const [isPushSubShown, togglePushSub] = useState(false);
    const [isAccepted, setAccepted] = useState(false);
    const [isGranted, setGranted] = useState(false);

    const { messagingEvent, setMessagingEvent } = useContext(MessagingContext);

    useEffect(() => {
        if (isActive && !!messagingEvent) {
            ReactGa.event({
                category: 'Push Custom',
                action: 'Shown',
                label: `static: ${!!isStatic}`,
            });

            togglePushSub(true);
        }
    }, [isActive, messagingEvent]);

    useEffect(() => {
        if (!messagingEvent && !isStatic) {
            setTimeout(hidePushSub, 100);
        }
    }, [messagingEvent]);

    useEffect(() => {
        window.addEventListener('pushPermission', handlePushPermission);

        return () => {
            window.removeEventListener('pushPermission', handlePushPermission);
        };
    });

    function hidePushSub() {
        togglePushSub(false);
    }

    function handlePushSubmit() {
        setAccepted(true);

        if (!messagingEvent) {
            ReactGa.event({
                category: 'Push Custom',
                action: 'Err',
                label: 'No messagingEvent',
            });

            return;
        }

        const { requestPemission, messaging, swReg } = messagingEvent;

        if (!requestPemission || !messaging) {
            ReactGa.event({
                category: 'Push Custom',
                action: 'Err',
                label: JSON.stringify({
                    pushEventDetail: !messagingEvent || 'ok',
                    requestPemission: !requestPemission || 'ok',
                    messaging: !messaging || 'ok',
                }),
            });

            return false;
        }

        ReactGa.event({
            category: 'Push Custom',
            action: 'Permission: Granted',
            label: `static: ${!!isStatic}`,
        });

        requestPemission(messaging, swReg, isStatic);
        setMessagingEvent(null);
    }

    function handlePushDeny() {
        ReactGa.event({
            category: 'Push Custom',
            action: 'Permission: Denied',
        });

        hidePushSub();
    }

    function handlePushPermission(e): void {
        const { permission } = e.detail;

        if (permission === 'granted') {
            setGranted(true);
        }
    }

    if (!isPushSubShown || !isActive) {
        return false;
    }

    return (
        <div className={cn('custom-push', {
            'custom-push_accepted': isAccepted,
            'custom-push_static': isStatic,
            'custom-push_granted': isGranted,
        })}
        >
            <button
                className="custom-push__close"
                onClick={handlePushDeny}
                type="button"
            >
                <CrossSvg className="custom-push__cross" />
            </button>
            <button className="custom-push__content" onMouseDown={handlePushSubmit} type="button">
                <BellSvg className="custom-push__bell" />
                <span
                    className="custom-push__title"
                    dangerouslySetInnerHTML={{
                        __html: (isStatic
                            // eslint-disable-next-line no-mixed-operators
                            && (isAccepted && !isGranted && 'Разрешите отправку')
                            // eslint-disable-next-line no-mixed-operators
                            || (isGranted && 'Спасибо!'))
                            || 'Подпишитесь на&nbsp;уведомления',
                    }}
                />
                <span className="custom-push__accept" />
            </button>
        </div>
    );
}
