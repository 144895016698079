import { useEffect } from 'react';

export default function useEffectOnScroll(func, depsArray): void {
    useEffect(() => {
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });

        return (): void => window.removeEventListener('scroll', onScroll);
    }, depsArray || []);

    function onScroll(): void {
        window.removeEventListener('scroll', onScroll);
        setTimeout(func, 1000);
    }
}
