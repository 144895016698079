import { useEffect, useMemo, useState } from 'react';

import getPocketByType from 'src/api/v4/getPocketByType';
import getRandomArrayItem from 'utils/getRandomArrayItem';

import type { EditorialBanner, EditorialBannerTypes } from 'entities/EditorialBanner';

interface Return {
    banner: EditorialBanner | null;
    isLoading: boolean;
}

interface SplitEditorialBanners {
    regular: EditorialBanner[],
    important: EditorialBanner[]
}

const useEditorialBanner = (type: EditorialBannerTypes): Return => {
    const [isLoading, setIsLoading] = useState(true);
    const [importantBanners, setImportantBanners] = useState<EditorialBanner[]>([]);
    const [regularBanners, setRegularBanners] = useState<EditorialBanner[]>([]);

    const splitImportantBanners = (banners: EditorialBanner[]): SplitEditorialBanners => {
        if (!banners) {
            return { regular: [], important: [] };
        }

        return banners.reduce<SplitEditorialBanners>((accumulator, banner) => {
            if (banner.is_important) {
                accumulator.important.push(banner);
            } else {
                accumulator.regular.push(banner);
            }

            return accumulator;
        }, { regular: [], important: [] });
    };

    const fetchBanner = async (bannerType: EditorialBannerTypes): Promise<void> => {
        try {
            setIsLoading(true);

            const response = await getPocketByType({
                pathParameters: { type: bannerType },
            });

            const { regular, important } = splitImportantBanners(response.results);

            setImportantBanners(important);
            setRegularBanners(regular);
        } catch (error) {
            // ignore
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchBanner(type);
    }, [type]);

    // Показываем приоритетный баннер с вероятностью 90%, f обычный с вероятностью 10%
    const banner = useMemo(() => {
        if (Math.random() > 0.1 && importantBanners.length > 0) {
            return getRandomArrayItem<EditorialBanner>(importantBanners);
        }

        if (regularBanners.length > 0) {
            return getRandomArrayItem<EditorialBanner>(regularBanners);
        }

        return null;
    }, [importantBanners, regularBanners]);

    return {
        banner,
        isLoading,
    };
};

export default useEditorialBanner;
