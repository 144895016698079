import React, { useContext } from 'react';

import cn from 'classnames';

import AuthContext from 'contexts/auth';

import { ReactComponent as PenSvg } from 'img/pen.svg';

import 'scss/skin/admin-edit.scss';

import type { FC } from 'react';

interface IAdminEditProps {
    className: string;
    isNews: boolean;
    alias: string;
    id: string;
    views: number;
}

const AdminEditLink: FC<IAdminEditProps> = ({
    className, isNews, alias, id, views,
}) => {
    const auth = useContext(AuthContext);

    if (!id || !auth?.user?.isEditor) {
        return null;
    }

    function getEditUrl(): string {
        let compAlias = '';

        if (alias === 'games') {
            compAlias = 'games';
        } else if (alias === 'gof') {
            compAlias = 'gof.vkplay.ru/story/gof';
        } else {
            compAlias = `${document.location.hostname}/story/${alias}`;
        }

        return `https://games.mail.ru/contentmanager/${compAlias}/${id}/edit`;
    }

    return (
        <a
            className={cn(
                'admin-edit',
                className,
                { 'admin-edit_news': isNews },
            )}
            href={getEditUrl()}
            target="__blank"
        >
            Просмотров:
            {' '}
            {views}
            <span className="admin-edit__dots">…</span>
            <PenSvg />
        </a>
    );
};

export default AdminEditLink;
