import { useCallback, useLayoutEffect, useRef } from 'react';

type Handler = (...args: any[]) => any;

const useEvent = (handler: Handler): Handler => {
    const handlerRef = useRef(null);

    useLayoutEffect(() => {
        handlerRef.current = handler;
    });

    return useCallback((...args) => handlerRef.current(...args), []);
};

export default useEvent;
