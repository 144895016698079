import React, {
    createContext, useContext, useMemo, useState,
} from 'react';

import type { Context } from 'react';

const AnchorsContext = createContext();

const AnchorsProvider: React.FC = ({ children }) => {
    const [headers, setHeaders] = useState([]);

    const value = useMemo(() => ({
        headers, setHeaders,
    }), [headers, setHeaders]);

    return (
        <AnchorsContext.Provider value={value}>
            {children}
        </AnchorsContext.Provider>
    );
};

function withAnchors(Component) {
    return (props) => (
        <AnchorsProvider>
            <Component {...props} />
        </AnchorsProvider>
    );
}

function useAnchors(): Context {
    const context = useContext(AnchorsContext);

    if (context === undefined) {
        throw new Error('useAnchors must be used within a AnchorsProvider');
    }

    return context;
}

export { AnchorsProvider, useAnchors, withAnchors };
