interface EventTypes {
    category?: string;
    action?: string;
    label?: string;
    value?: string;
}

function multipleTrackers(data, callback): void {
    if (!window?.ga?.getAll || typeof window.ga.getAll !== 'function') {
        return;
    }

    const trackers = window.ga.getAll();

    for (let i = 0; i < trackers.length; ++i) {
        const tracker = trackers[i];
        callback({ ...data, tracker });
    }
}

function sendPageview({
    location, title, displayMode, tracker,
}) {
    if (!tracker) {
        return;
    }

    if (displayMode) {
        tracker.set('dimension6', displayMode);
    }

    tracker.send({
        hitType: 'pageview',
        location,
        title,
    });
}

function sendEvent({
    category, action, label, value, tracker,
}) {
    if (!tracker) {
        return;
    }

    tracker.send({
        hitType: 'event',
        eventCategory: category,
        eventAction: action,
        eventLabel: label,
        eventValue: value,
    });
}

function sendDimension({ dimension, value, tracker }) {
    if (!tracker) {
        return;
    }

    tracker.set(dimension, value);
}

const ReactGa = {
    pageview: (location: string, title: string, displayMode: string): void => {
        multipleTrackers({ location, title, displayMode }, sendPageview);
    },
    event: ({
        category, action, label, value,
    }: EventTypes): void => {
        multipleTrackers({
            category, action, label, value,
        }, sendEvent);
    },
    dimension: (dimension: string, value: string): void => {
        multipleTrackers({ dimension, value }, sendDimension);
    },
};

export default ReactGa;
