import React, { useContext, Suspense, memo } from 'react';

import DeviceContext from 'contexts/device';

import getDisplayMode from 'utils/getDisplayMode';

import useScrolledState from 'hooks/useScrolledState';

import type { FC, ReactNode } from 'react';

interface Props {
    ifMobile?: boolean;
    ifDesktop?: boolean;
    ifScrolled?: boolean;
    noRenderWithParam?: string;
    ifNot?: boolean | boolean[];
    ifNotUserAgent?: boolean;
    ifNotPWA?: boolean;
    userAgent?: string;
    children?: ReactNode | ReactNode[]
}

const ConditionalRenderer: FC<Props> = ({
    ifMobile,
    ifDesktop,
    ifScrolled,
    ifNot,
    ifNotUserAgent,
    ifNotPWA,
    noRenderWithParam,
    userAgent,
    children,
}) => {
    const { isMobile, isDesktop } = useContext(DeviceContext);
    const isScrolled = useScrolledState();
    const isPWA = getDisplayMode() === 'PWA';

    if (noRenderWithParam) {
        if (Array.isArray(noRenderWithParam)) {
            for (let i = 0; i < noRenderWithParam.length; i++) {
                if (window.location.search.includes(noRenderWithParam[i])) {
                    return null;
                }
            }
        }

        if (window.location.search.includes(noRenderWithParam)) {
            return null;
        }
    }

    if (ifNot) {
        if (Array.isArray(ifNot)) {
            for (let i = 0; i < ifNot.length; i++) {
                if (ifNot[i]) {
                    return null;
                }
            }
        } else {
            return null;
        }
    }

    if (ifNotUserAgent && window.navigator.userAgent.includes(userAgent)) {
        return null;
    }

    if (ifScrolled && !isScrolled) {
        return null;
    }

    if (ifMobile && !isMobile) {
        return null;
    }

    if (ifDesktop && !isDesktop) {
        return null;
    }

    if (ifNotPWA && isPWA) {
        return null;
    }

    return (
        <Suspense fallback={null}>
            {children}
        </Suspense>
    );
};

export default memo(ConditionalRenderer);
