import { useEffect, useState } from 'react';

import getQuiz from 'src/api/v1/getQuiz';
import getTrivia from 'src/api/v1/getTrivia';
import postQuizResults from 'src/api/v1/postQuizResults';
import postTriviaCheck from 'src/api/v1/postTriviaCheck';
import postTriviaResults from 'src/api/v1/postTriviaResults';

import type { QuizRequestProps } from './types';
import type { Quiz } from 'entities/Quiz';

const useQuizData = ({ id, type }: QuizRequestProps) => {
    const [data, setData] = useState<Quiz>({});

    useEffect(() => {
        initQuiz();
    }, [id]);

    async function initQuiz(): Promise<void> {
        const response = type === 'quiz'
            ? await getQuiz({ pathParameters: { slug: id } })
            : await getTrivia({ pathParameters: { slug: id } });

        setData(response);
    }

    function checkAnswer(answerId: number) {
        if (type === 'quiz') {
            return;
        }

        return postTriviaCheck({
            pathParameters: { slug: id },
            body: { answer: answerId },
        });
    }

    function checkResults(answerIds: number[]) {
        const answersData = { answers: answerIds };

        return type === 'trivia'
            ? postTriviaResults({ pathParameters: { slug: id }, body: answersData })
            : postQuizResults({ pathParameters: { slug: id }, body: answersData });
    }

    return {
        data,
        checkAnswer,
        checkResults,
    };
};

export default useQuizData;
