import photon from 'photon';

import { IMAGE_FORMAT_MAP } from 'atoms/Picture/constants';

import type { BaseProps } from 'atoms/Picture/types';

interface Options extends Pick<BaseProps, 'src' | 'width' | 'height' | 'quality' | 'noGif'>{
    type: string;
}

const getSrcSet = ({
    src,
    width,
    height,
    type,
    quality,
    noGif,
}: Options): string => {
    const x1 = photon(
        src,
        width,
        height,
        1,
        IMAGE_FORMAT_MAP[type],
        quality,
        noGif,
    );

    const x2 = photon(
        src,
        width,
        height,
        2,
        IMAGE_FORMAT_MAP[type],
        quality,
        noGif,
    );

    return `${x1} 1x, ${x2} 2x`;
};

export default getSrcSet;
