import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';

import QuizCover from './QuizCover';
import QuizQuestion from './QuizQuestion';
import QuizResult from './QuizResult';

import './quiz.scss';

import type { QuizProps } from './types';
import type { QuizResults } from 'entities/Quiz';
import type { FC } from 'react';

const Quiz: FC<QuizProps> = ({
    image: coverImage,
    title,
    questions,
    checkAnswer,
    checkResults,
}) => {
    const [currentState, setCurrentState] = useState<'start' | 'questions' | 'result'>('start');
    const [currentQuestion, setQuestion] = useState<number>(0);
    const [myAnswers, setMyAnswers] = useState<number[]>([]);
    const [rightAnswers, setRightAnswers] = useState<string[]>([]);
    const [results, setResults] = useState<QuizResults>();

    const checkQuizResults = useCallback(async () => {
        try {
            const quizResults = await checkResults(myAnswers);

            setResults(quizResults);
        } catch (error) {
            // ignore
        }
    }, [myAnswers]);

    useEffect(() => {
        if (rightAnswers.length !== questions?.length) {
            return;
        }

        checkQuizResults();
    }, [rightAnswers]);

    const handleClick = useCallback(() => {
        if (currentState === 'start') {
            setCurrentState('questions');

            return;
        }

        if (currentState === 'questions' && currentQuestion + 1 === questions?.length) {
            setCurrentState('result');

            return;
        }

        setQuestion(currentQuestion + 1);
    }, [questions, currentState, currentQuestion]);

    const handleAnswer = useCallback(async (answerId: number) => {
        if (!answerId) {
            return;
        }

        try {
            const answer = await checkAnswer(answerId);

            if (!answer) {
                return;
            }

            setMyAnswers((prevMyAnswers) => [...prevMyAnswers, answerId]);
            setRightAnswers((prevRightAnswers) => [...prevRightAnswers, `${answer.id}`]);
        } catch (error) {
            // ignore
        }
    }, [questions]);

    const currentImage = useMemo(() => {
        if (currentState === 'questions') {
            return questions[currentQuestion]?.image || '';
        }

        if (currentState === 'result') {
            return results?.image || '';
        }

        return coverImage;
    }, [currentState, currentQuestion, questions, results]);

    return (
        <div className="quiz-widget">
            <QuizCover
                title={title}
                image={currentImage}
                isButtonHidden={
                    (currentState !== 'start' && rightAnswers.length === currentQuestion)
                    || currentState === 'result'
                }
                buttonState={rightAnswers.length >= (questions?.length || 0)
                    ? 'to-results'
                    : currentState}
                onClick={handleClick}
            />
            {currentState === 'result' && results && (
                <QuizResult {...results} />
            )}
            {currentState === 'questions' && questions?.map((question, i) => (
                <QuizQuestion
                    key={question?.id}
                    isCurrent={i === currentQuestion}
                    answers={question?.answers}
                    rightAnswer={rightAnswers[i]}
                    text={question?.text}
                    onAnswer={handleAnswer}
                />
            ))}
        </div>
    );
};

export default Quiz;
