import lazyPreloadable from 'utils/lazyPreloadable';

const News = lazyPreloadable(() => import('organisms/News'));
const Article = lazyPreloadable(() => import('organisms/Article'));
const Story = lazyPreloadable(() => import('templates/Story'));

const preloadMap = {
    news(): void {
        Story.preload();
        News.preload();
    },
    article(): void {
        Story.preload();
        Article.preload();
    },
};

export default function pagePreloader(page: string): void {
    if (preloadMap[page as keyof typeof preloadMap] === undefined) {
        return;
    }

    preloadMap[page as keyof typeof preloadMap]();
}
