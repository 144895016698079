import React from 'react';

import cn from 'classnames';

import 'scss/skin/rating.scss';

const Rating: React.FunctionComponent = ({ className, rating }) => {
    if (!rating || !+rating) {
        return null;
    }

    return (
        <div className={cn(
            'rating',
            'rating_light',
            `rating_level-${Math.round(rating / 2)}`,
            {
                [className]: className,
            },
        )}
        >
            {rating === '10.0' ? '10' : rating}
        </div>
    );
};

export default Rating;
