import React, { useEffect, useRef, useState } from 'react';

import cn from 'classnames';

import Button from 'atoms/Button';

import 'scss/skin/tabs.scss';

interface Props {
    id?: string;
    className: string;
    tabs: any;
    history?: any;
}

const PRETTY_GAP = 20; // красивое

const Tabs: React.FC<Props> = ({
    history,
    id,
    tabs,
    className,
}) => {
    const [current, setCurrent] = useState({
        pathname: window.location.pathname,
        href: window.location.href,
    });

    useEffect(() => {
        if (history) {
            history.listen((_, action) => {
                if (action === 'POP') {
                    setCurrent({
                        pathname: window.location.pathname,
                        href: window.location.href,
                    });
                }
            });
        }
    }, [history]);

    const containerRef = useRef(null);

    useEffect(() => {
        initScrollRecalc();
    }, [current]);

    function handleClick(e: React.MouseEvent<HTMLLinkElement>): void {
        const { outer } = e.currentTarget.dataset;

        if (outer === 'true') {
            return;
        }

        setCurrent({
            pathname: window.location.pathname,
            href: window.location.href,
        });
    }

    function initScrollRecalc(): void {
        const container = containerRef.current;

        if (container.scrollWidth <= container.clientWidth) {
            return;
        }

        const activeItem = container.getElementsByClassName('button_primary-imp')[0];

        if (!activeItem) {
            return;
        }

        const itemRect = activeItem.getBoundingClientRect();

        if (itemRect.left < 0) {
            container.scrollLeft = container.scrollLeft + itemRect.left - PRETTY_GAP;

            return;
        }

        if (itemRect.right > window.innerWidth) {
            container.scrollLeft = container.scrollLeft + itemRect.right - window.innerWidth + PRETTY_GAP;
        }
    }

    return (
        <nav id={id} className={cn('tab-panel', className)} ref={containerRef}>
            {tabs.map((tab) => (tab.link ? (
                <Button
                    exact={tab.isExact}
                    key={tab.link}
                    href={tab.link}
                    className="tab-panel__tab"
                    variant="monochrome"
                    variantActive="primary"
                    role="link"
                    shape="round"
                    onClick={handleClick}
                >
                    {tab.labelButton || tab.label}
                </Button>
            ) : null))}
        </nav>
    );
};

export default Tabs;
