import React, { Suspense } from 'react';

import 'scss/skin/main.scss';

const Main: React.FC = ({ className, children }) => (
    <main className={`main ${className || ''}`} role="main">
        <Suspense fallback={null}>
            {children}
        </Suspense>
    </main>
);

export default Main;
