import type { EditorialBannerFeedPlace } from 'entities/EditorialBanner';

interface Options {
    isMobile?: boolean;
    editorialBannerPlace?: EditorialBannerFeedPlace;
}

const useEditorialBannerPlaceData = ({ isMobile, editorialBannerPlace }: Options) => {
    if (!editorialBannerPlace) {
        return {
            place: undefined,
            type: 'commercial_desktop' as const,
        };
    }

    if (isMobile) {
        return {
            place: editorialBannerPlace.mobile,
            type: 'commercial_mobile' as const,
        };
    }

    return {
        place: editorialBannerPlace.desktop,
        type: 'commercial_desktop' as const,
    };
};

export default useEditorialBannerPlaceData;
