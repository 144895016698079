import { createContext } from 'react';

import type { PcV2ProfileSchema } from 'schemas/pc/v2/profile';
import type { EmptyObject } from 'static/@types/globals';

interface AuthCTX {
    user: PcV2ProfileSchema | EmptyObject;
}

const AuthContext = createContext<AuthCTX | EmptyObject>({});

export default AuthContext;
