import React, {
    Suspense, useContext, useEffect, useState,
} from 'react';

import Subscription from '@vkplay/subscription';

import AuthContext from 'contexts/auth';

import sendExtraEvents from 'utils/VKPlayCounters';

import Button from 'atoms/Button';
import Picture from 'atoms/Picture';
import Pixel from 'atoms/Pixel';

import useEditorialBanner from './hooks/useEditorialBanner';
import getEmailSubscribed from './utils/getEmailSubscribed';

import './editorial-banner-right.scss';

import type { FC, MouseEvent } from 'react';

const EditorialBannerRight: FC = () => {
    const { user } = useContext(AuthContext);
    const { banner } = useEditorialBanner('banner_right');

    const [showEmailSubscription, setShowEmailSubscription] = useState(false);

    useEffect(() => {
        const isEmailSubscribed = getEmailSubscribed();

        if (!isEmailSubscribed) {
            return;
        }

        // 40% вероятность показа баннера с подпиской
        if (Math.random() >= 0.6) {
            setShowEmailSubscription(true);
        }
    }, []);

    const handleClick = (event: MouseEvent<HTMLAnchorElement>): void => {
        const { title } = event.currentTarget.dataset;

        sendExtraEvents({
            action: 'promo_click',
            additionalData: title,
        });
    };

    const handleSubscriptionClose = (): void => {
        setShowEmailSubscription(false);
    };

    if (showEmailSubscription) {
        console.debug('SUBSCRIPTION INIT');

        return (
            <Suspense fallback={null}>
                <Subscription user={user} view="compact" onClose={handleSubscriptionClose} />
            </Suspense>
        );
    }

    if (!banner) {
        return null;
    }

    return (
        <div className="editorial-banner-right">
            {banner.pixel && <Pixel src={banner.pixel} />}
            {banner.picture && (
                <Picture
                    classNameWrapper="editorial-banner-right__image"
                    src={banner.picture}
                    height={150}
                    alt={banner.name}
                />
            )}
            <span className="editorial-banner-right__name">{banner.name}</span>
            {banner.descr && (
                <span
                    className="editorial-banner-right__description"
                    dangerouslySetInnerHTML={{ __html: banner.descr }}
                />
            )}
            <Button
                className="editorial-banner-right__button"
                href={banner.url}
                label="Подробнее"
                role="link"
                isFullWidth
                isOuterLink
                targetBlank
                data-title={banner.name}
                onClick={handleClick}
            />
        </div>
    );
};

export default EditorialBannerRight;
