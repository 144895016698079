import React from 'react';

import cn from 'classnames';
import { Link } from 'react-router-dom';

import Rating from 'atoms/Rating';

import { ReactComponent as StarSvg } from 'img/game-rating-star.svg';

import 'scss/skin/game-rating.scss';

const GameRatingMobile: React.FunctionComponent = ({
    title, link, rating, className,
}) => {
    if (!rating || rating === '0.0') {
        return null;
    }

    const Tag = link ? Link : 'div';

    return (
        <Tag to={link} className={cn('game-rating-m', className, { 'game-rating-m_link': link })}>
            <StarSvg className="game-rating-m__icon" />
            <span>
                {rating ? (
                    <Rating className="game-rating-m__rating" rating={rating} />
                ) : (
                    <span className="game-rating-m_empty">—</span>
                )}
                <p className="game-rating-m__title">{title}</p>
            </span>
        </Tag>
    );
};

export default GameRatingMobile;
