import React, { useState, useMemo, useCallback } from 'react';

import cn from 'classnames';

import sendExtraEvents from 'utils/VKPlayCounters';

import Button from 'atoms/Button';

import useEvent from 'hooks/useEvent';

import { ReactComponent as PagingArrowSvg } from 'img/paging-combined-arrow.svg';

import 'scss/skin/paging-combined.scss';

import type { FC, SyntheticEvent } from 'react';

interface Props {
    itemsLimit: number;
    totalItems: number;
    totalPages: number;
    forceReload: () => void;
    loadMore: () => void;
    currentPage: number;
    startingPage: number;
    sidePages: {
        prev: string;
        next: string;
    };
    scrollTo: string;
    noLimitControls: boolean;
}

const LIMIT_LIST = [16, 32, 48];

const messages = {
    ru: {
        showBy: 'Показывать по',
        showing: 'Показано',
        of: 'из',
        moar: 'Показать ещё',
    },
    en: {
        showBy: 'Show by',
        showing: 'Showing',
        of: 'of',
        moar: 'Show more',
    },
};

const PagingCombined: FC<Props> = ({
    itemsLimit, totalItems, totalPages, forceReload,
    loadMore, currentPage, startingPage, sidePages, scrollTo,
    noLimitControls,
}) => {
    const [useForcedReload, setForcedReload] = useState(false); // TODO: что-то сделать с этим костылём

    function getSearchString(params: Record<string, string>): string {
        const { search } = document.location;
        const searchQuery = new URLSearchParams(search);

        Object.entries(params).forEach(([key, value]) => {
            if (!value) {
                searchQuery.delete(key);
            } else {
                searchQuery.set(key, value);
            }
        });

        return `?${searchQuery.toString()}`;
    }

    const setPageHref = useCallback((index: number, isAbsolute?: boolean) => {
        const newPage = index + (isAbsolute ? 0 : currentPage);

        return getSearchString({ page: `${newPage}` });
    }, [currentPage]);

    const setLimitHref = useCallback((limit: number) => getSearchString({
        limit: `${limit}`,
        page: null,
    }), []);

    const lastPage = useMemo(() => Math.ceil(totalItems / itemsLimit), [totalItems, itemsLimit]);

    const handleLoadMore = useEvent((): void => {
        setForcedReload(true);
        loadMore();
    });

    const handleForceReload = useCallback((e: SyntheticEvent<HTMLLinkElement>): void => {
        setForcedReload(false);
        forceReload();

        const { index } = e.currentTarget.dataset;

        sendExtraEvents({
            action: 'pagination_click',
            additionalData: index,
        });
    }, []);

    const handlePaginationClick = useCallback((e: SyntheticEvent<HTMLLinkElement>): void => {
        const { index } = e.currentTarget.dataset;

        sendExtraEvents({
            action: 'pagination_click',
            additionalData: index,
        });
    }, []);

    const handleLimitClick = useCallback((e: SyntheticEvent<HTMLLinkElement>): void => {
        const { limit } = e.currentTarget.dataset;

        sendExtraEvents({
            action: 'limit_click',
            additionalData: limit,
        });
    }, []);

    const buttons = useMemo(() => {
        if (!lastPage) {
            return null;
        }

        const buttonsArr = [];

        if (currentPage < 4) {
            for (let i = 0; i < 4 && i < totalPages; i++) {
                buttonsArr.push(
                    <Button
                    // eslint-disable-next-line react/no-array-index-key
                        key={i}
                        className={cn('paging-combined__item', { active: i + 1 === currentPage })}
                        href={setPageHref(i + 1, true)}
                        role="link"
                        scrollTo={scrollTo}
                        label={i + 1}
                        data-index={i + 1}
                        onClick={useForcedReload ? handleForceReload : handlePaginationClick}
                        noStyling
                    />,
                );
            }
        } else if (currentPage + 3 > lastPage) {
            for (let i = 0; i < 4; i++) {
                buttonsArr.unshift(
                    <Button
                        // eslint-disable-next-line react/no-array-index-key
                        key={lastPage - i}
                        className={cn('paging-combined__item', { active: lastPage - i === currentPage })}
                        href={setPageHref(lastPage - i, true)}
                        role="link"
                        scrollTo={scrollTo}
                        label={lastPage - i}
                        data-index={lastPage - i}
                        onClick={useForcedReload ? handleForceReload : handlePaginationClick}
                        noStyling
                    />,
                );
            }
        } else {
            for (let i = -1; i < 2; i++) {
                buttonsArr.push(
                    <Button
                        // eslint-disable-next-line react/no-array-index-key
                        key={currentPage + i}
                        className={cn('paging-combined__item', { active: currentPage + i === currentPage })}
                        href={setPageHref(i)}
                        role="link"
                        scrollTo={scrollTo}
                        label={currentPage + i}
                        data-index={currentPage + i}
                        onClick={useForcedReload ? handleForceReload : handlePaginationClick}
                        noStyling
                    />,
                );
            }
        }

        if (currentPage >= 4) {
            buttonsArr.unshift(
                <>
                    <Button
                        // eslint-disable-next-line react/no-array-index-key
                        key={1}
                        className="paging-combined__item"
                        href={setPageHref(1, true)}
                        role="link"
                        scrollTo={scrollTo}
                        label={1}
                        data-index={1}
                        onClick={useForcedReload ? handleForceReload : handlePaginationClick}
                        noStyling
                    />
                    <span className="paging-combined__more">...</span>
                </>,
            );
        }

        if (currentPage + 2 < lastPage) {
            buttonsArr.push(
                <>
                    <span className="paging-combined__more">...</span>
                    <Button
                        // eslint-disable-next-line react/no-array-index-key
                        key={lastPage}
                        className="paging-combined__item"
                        href={setPageHref(lastPage, true)}
                        role="link"
                        scrollTo={scrollTo}
                        label={lastPage}
                        data-index={lastPage}
                        onClick={useForcedReload ? handleForceReload : handlePaginationClick}
                        noStyling
                    />
                </>,
            );
        }

        return buttonsArr;
    }, [currentPage, lastPage, totalPages, useForcedReload, setPageHref]);

    return (
        <div className="paging-combined__wrap feed__more-wrap">
            {!!sidePages.next && (
                <Button
                    className="feed__more"
                    onClick={handleLoadMore}
                    label={messages.ru.moar}
                    variant="outline-primary"
                />
            )}
            <nav className="paging-combined">
                <div className="paging-combined__list-items" data-test="pagination-list">
                    <Button
                        className={cn('paging-combined__arrow prev', {
                            disabled: !sidePages.prev,
                        })}
                        href={sidePages.prev}
                        role="link"
                        scrollTo={scrollTo}
                        data-index={currentPage - 1}
                        onClick={useForcedReload ? handleForceReload : handlePaginationClick}
                        noStyling
                    >
                        <PagingArrowSvg className="paging-combined__arrow-icon" />
                    </Button>
                    {buttons}
                    <Button
                        className={cn('paging-combined__arrow next', {
                            disabled: !sidePages.next,
                        })}
                        href={sidePages.next}
                        role="link"
                        scrollTo={scrollTo}
                        data-index={currentPage + 1}
                        onClick={useForcedReload ? handleForceReload : handlePaginationClick}
                        noStyling
                    >
                        <PagingArrowSvg className="paging-combined__arrow-icon" />
                    </Button>
                </div>
                <div className="paging-combined__show">
                    <div className="paging-combined__results">
                        {messages.ru.showing}
                        {' '}
                        {((startingPage - 1) * itemsLimit) + 1}
                        {' '}
                        —
                        {' '}
                        {+itemsLimit * +currentPage > totalItems ? totalItems : +itemsLimit * +currentPage}
                        {' '}
                        {messages.ru.of}
                        {' '}
                        {totalItems}
                    </div>
                    {!noLimitControls && (
                        <div className="paging-combined__limit">
                            <div className="paging-combined__limit-text">
                                {messages.ru.showBy}
                                :
                            </div>
                            <div>
                                {LIMIT_LIST.map((limit) => (
                                    <Button
                                    // eslint-disable-next-line react/no-array-index-key
                                        key={limit}
                                        className={cn('paging-combined__limit-item', {
                                            active: +limit === +itemsLimit,
                                        })}
                                        href={setLimitHref(limit)}
                                        role="link"
                                        scrollTo={scrollTo}
                                        label={limit}
                                        data-limit={limit}
                                        onClick={handleLimitClick}
                                        noStyling
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </nav>
        </div>
    );
};

export default PagingCombined;
