import type { AllowedQueryParameters } from 'src/atoms/Head';

const ALLOWED_PARAMS: Record<string, string[]>[] = [];

export default function filterSearchParams(url?: string, ignoreParams: AllowedQueryParameters = []): string {
    if (!url) {
        return '';
    }

    let urlObj: URL;
    let newUrl: URL;

    try {
        urlObj = new URL(url);
        newUrl = new URL(`${urlObj.origin}${urlObj.pathname}`);
    } catch (e) {
        urlObj = new URL(window.location.href);
        newUrl = new URL(`${urlObj.origin}${urlObj.pathname}`);
    }

    const ignoreParameters = [...ALLOWED_PARAMS, ...ignoreParams];

    ignoreParameters.forEach((param) => {
        let paramKey: string;
        let allowedValues: string[] = [];

        if (typeof param === 'string') {
            paramKey = param;
        } else {
            const [key, values] = Object.entries(param)[0];

            paramKey = key;
            allowedValues = values;
        }

        const paramValue = urlObj.searchParams.get(paramKey);

        if (!paramValue || !allowedValues.includes(paramValue)) {
            return;
        }

        newUrl.searchParams.set(paramKey, paramValue);
    });

    return newUrl.toString();
}
