import React from 'react';

import cn from 'classnames';

import Picture from 'atoms/Picture';
import Pixel from 'atoms/Pixel';

import useEditorialBanner from './hooks/useEditorialBanner';

import './editorial-banner-feed.scss';

import type { Props } from './EditorialBanners.types';
import type { FC } from 'react';

const BANNER_HEIGHT = 302;

const EditorialBannerFeed: FC<Props> = ({ type }) => {
    const { isLoading, banner } = useEditorialBanner(type);

    if (isLoading) {
        return <div className={cn('editorial-banner-feed', 'editorial-banner-feed__skeleton')} />;
    }

    if (!banner) {
        return null;
    }

    const originalObjectFit = type === 'commercial_mobile' ? 'contain' : 'cover';

    return (
        <a
            className="editorial-banner-feed"
            href={banner.url}
            target="__blank"
            rel="noreferrer noopener"
        >
            {banner.pixel && <Pixel src={banner.pixel} />}
            {banner.picture && (
                <Picture
                    classNameWrapper="editorial-banner-feed__image"
                    src={banner.picture}
                    height={BANNER_HEIGHT}
                    alt={banner.name}
                    originalObjectFit={originalObjectFit}
                />
            )}
            <div className="editorial-banner-feed__content">
                <p className="editorial-banner-feed__ad-label">Реклама</p>
                <div>
                    <p className="editorial-banner-feed__name">{banner.name}</p>
                    {banner.descr && (
                        <p
                            className="editorial-banner-feed__description"
                            dangerouslySetInnerHTML={{ __html: banner.descr }}
                        />
                    )}
                </div>
            </div>
        </a>
    );
};

export default EditorialBannerFeed;
