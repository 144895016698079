import React from 'react';

import cn from 'classnames';

import CustomLink from 'atoms/CustomLink';

import 'scss/button.scss';

function Button({
    className, onClick, variant, isFullWidth,
    label, role, href, type, children, size,
    isDisabled, variantActive, shape, isOuterLink,
    noPointerEvents, targetBlank, exact, scrollTo,
    noStyling,
    ...restProps
}) {
    if (role.includes('link')) {
        return (
            <CustomLink
                to={href}
                exact={exact}
                shouldScrollTop={role === 'link-top'}
                scrollTo={scrollTo}
                className={cn(noStyling ? className : [
                    'button',
                    `button_${size}`,
                    `button_${variant}`,
                    `button_${shape}`,
                    {
                        button_full: isFullWidth,
                        button_disabled: isDisabled,
                        'button_no-events': noPointerEvents,
                        [className]: className,
                    },
                ])}
                activeClassName={variantActive && `button_${variantActive}-imp`}
                isOuterLink={isOuterLink}
                targetBlank={targetBlank}
                onClick={onClick}
                {...restProps}
            >
                {label || children}
            </CustomLink>
        );
    }

    return (
        <button
            onClick={onClick}
            className={cn(
                'button',
                `button_${size}`,
                `button_${variant}`,
                `button_${shape}`,
                {
                    button_full: isFullWidth,
                    button_disabled: isDisabled,
                    'button_no-events': noPointerEvents,
                    [className]: className,
                },
            )}
            /* eslint-disable-next-line react/button-has-type */
            type={type}
            {...restProps}
        >
            {label || children}
        </button>
    );
}

Button.defaultProps = {
    type: 'button',
    role: 'button',
    size: 'md',
    variant: 'primary',
    shape: 'square',
    title: '',
};

export default Button;
