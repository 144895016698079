import React, { useState, useMemo, useEffect } from 'react';

import cn from 'classnames';
import { Link } from 'react-router-dom';

import { releaseRegions } from 'aliases';

import AdminEditLink from 'atoms/AdminEditLink';
import GameInfo from 'atoms/GameInfo';
import Picture from 'atoms/Picture';
import Rating from 'atoms/Rating';
import Social from 'atoms/Social';
import Time from 'atoms/Time';
import ConditionalRenderer from 'src/atoms/ConditionalRenderer';

import DownloadAside from 'molecules/DownloadAside';
import GameRatingMobile from 'molecules/GameRatingMobile';
import PlatformTag from 'molecules/PlatformTag';

import { getStoryDataV3 } from 'neurons';

import { ReactComponent as LinkSvg } from 'img/game-link.svg';
import { ReactComponent as PlanetSvg } from 'img/game-planet.svg';
import { ReactComponent as EuSvg } from 'img/game-region-eu.svg';
import { ReactComponent as JpSvg } from 'img/game-region-jp.svg';
import { ReactComponent as KrSvg } from 'img/game-region-kr.svg';
import { ReactComponent as RuSvg } from 'img/game-region-ru.svg';
import { ReactComponent as UsSvg } from 'img/game-region-us.svg';
import { ReactComponent as WoSvg } from 'img/game-region-wo.svg';
import { ReactComponent as LockSvg } from 'img/lock.svg';
import { ReactComponent as ArrowSvg } from 'img/menu-arrow.svg';

const regionIcons = {
    wo: WoSvg,
    ru: RuSvg,
    eu: EuSvg,
    us: UsSvg,
    kr: KrSvg,
    jp: JpSvg,
};

const movingParts = {
    'dates-by-region': {
        main: 'Даты выхода',
        sub: 'По регионам',
    },
    'dates-by-platform': {
        main: 'Даты выхода',
        sub: 'По платформам',
    },
};

interface ReleaseInfo {
    regions: string[];
    platforms: Set<string>;
}

const GameCover: React.FunctionComponent = ({ game, description, tabLabel }) => {
    const {
        id, name, genre, rating, views,
        best_review: reviewSlug,
        is_closed: isClosed,
        picture: image,
        developer_list: developers,
        publisher_list: publishers,
        play_mode_list: modes,
        rel_by_reg: regions,
        site_list: sites,
    } = game;

    const [reviewTitle, setReviewTitle] = useState(null);
    const [movedType, setMovedType] = useState('');

    const datesByRegionAndPlatform = useMemo(() => {
        const dates: Record<string, ReleaseInfo> = {};

        regions.forEach((region) => {
            region.date_list.forEach((dateObj) => {
                const dateId = `${dateObj.date}_${dateObj.date_text}`;
                const platforms = dateObj.platform_list.map((platform) => platform.slug);

                if (!dates[dateId]) {
                    dates[dateId] = {
                        regions: [region.region],
                        platforms: new Set(platforms),
                    };

                    return;
                }

                dates[dateId].regions.push(region.region);
                dates[dateId].platforms.add(...platforms);
            });
        }, []);

        return dates;
    }, [regions]);

    useEffect(() => {
        if (reviewSlug) {
            getStoryDataV3({ slug: reviewSlug, alias: 'review', short: true }).then((data) => {
                if (!(data instanceof Error)) {
                    setReviewTitle(data.title);
                }
            });
        } else {
            setReviewTitle(null);
        }
    }, [id]);

    function handleMoreDatesClick(e) {
        const { type } = e.currentTarget.dataset;

        setMovedType(type);
    }

    function renderMainDate() {
        if (!regions || !regions.length) {
            return null;
        }

        const region = regions[0];
        const Icon = regionIcons[region.region.code];
        const { date, date_text } = region.date_list[0];

        return (
            <>
                <span
                    className="game-cover__info-date"
                    key={region.region.code}
                    title={releaseRegions[region.region.code]}
                >
                    <Icon className="game-cover__info-icon" />
                    <span className="game-cover__info-wrap">
                        <Time
                            className="game-cover__info-main-time"
                            time={date}
                            dateType={date_text}
                            noExactTime
                            fullMonth
                        />
                    </span>
                </span>
                <div className="game-cover__info-date">
                    <button
                        onClick={handleMoreDatesClick}
                        className="game-cover__date-more"
                        data-type="dates-by-region"
                        type="button"
                    >
                        По регионам
                        <ArrowSvg className="game-cover__date-more-icon" />
                    </button>
                    <button
                        onClick={handleMoreDatesClick}
                        className="game-cover__date-more"
                        data-type="dates-by-platform"
                        type="button"
                    >
                        По платформам
                        <ArrowSvg className="game-cover__date-more-icon" />
                    </button>
                </div>
            </>
        );
    }

    function renderDatesByRegion() {
        if (!regions || !regions.length) {
            return null;
        }

        return Object.entries(datesByRegionAndPlatform).map(([dateId, data]) => {
            const [date, dateText] = dateId.split('_');

            return (
                <span key={dateId} className="game-cover__info-date" title={date}>
                    <Time className="game-cover__info-time" time={date} dateType={dateText} noExactTime fullMonth />
                    {data.regions.map((region) => {
                        const Icon = regionIcons[region.code];

                        return (
                            <span key={`${dateId}-${region.code}`} className="game-cover__info-wrap">
                                <Icon className="game-cover__info-icon" />
                                <span className="game-cover__info-country">{region.name}</span>
                            </span>
                        );
                    })}
                </span>
            );
        });
    }

    function renderDatesByPlatform() {
        if (!regions || !regions.length) {
            return null;
        }

        return Object.entries(datesByRegionAndPlatform).map(([dateId, data]) => {
            const [date, dateText] = dateId.split('_');

            return (
                <span key={dateId} className="game-cover__info-date" title={date}>
                    <Time className="game-cover__info-time" time={date} dateType={dateText} noExactTime fullMonth />
                    {[...(data?.platforms || [])].map((platform) => (
                        <span key={`${dateId}-${platform}`} className="game-cover__info-wrap">
                            <PlatformTag type={platform} />
                        </span>
                    ))}
                </span>
            );
        });
    }

    function renderSite() {
        if (!sites || !sites.length) {
            return null;
        }

        const nonEmptySites = sites.filter((site) => site.text !== '-');

        if (!nonEmptySites.length) {
            return null;
        }

        return (
            <span className="game-cover__info-sites">
                {nonEmptySites.map((site) => (
                    <a
                        key={site.url}
                        className="game-cover__site"
                        href={site.url}
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                    >
                        <LinkSvg className="game-cover__site-icon" />
                        {site.text || name}
                    </a>
                ))}
            </span>
        );
    }

    function renderReviewTitle() {
        if (!reviewTitle) {
            return null;
        }

        const title = reviewTitle.replace(name, `<span class="game-cover__review-title_bold">${name}</span>`);

        return <span className="game-cover__review-title" dangerouslySetInnerHTML={{ __html: title }} />;
    }

    const gameInfoMap = [
        {
            title: 'Жанр',
            list: genre,
            limit: 5,
        },
        {
            title: 'Режим',
            list: modes,
            limit: 5,
        },
        {
            title: 'Разработчик',
            list: developers,
        },
        {
            title: 'Издатель',
            list: publishers,
            limit: 5,
        },
        (!isClosed ? {
            title: 'Дата выхода',
            children: renderMainDate(),
            classNameTitle: 'game-cover__info-title-date',
            classNameItem: 'game-cover__info-item-date',
        } : {}),
        {
            title: 'Ссылки',
            children: renderSite(),
            classNameTitle: 'game-cover__info-title-link',
            classNameItem: 'game-cover__info-item-link',
        },
    ];

    function getDisabledButtonTypes() {
        const disabledButtons: Set<string> = new Set(['cloud']);

        Object.entries(datesByRegionAndPlatform).forEach(([dateId, { platforms }]) => {
            const [date] = dateId.split('_');

            const isPC = platforms.has('pc_windows');
            const isReleased = new Date(date) < new Date();

            if (isPC && isReleased) {
                disabledButtons.delete('cloud');
            }
        });

        return Array.from(disabledButtons);
    }

    return (
        <div className="game-cover">
            <div className="game-cover__mobile-wrapper">
                <div className="game-cover__image-wrap">
                    <div className="game-cover__absolute-block">
                        {reviewSlug ? (
                            <Link
                                className="game-cover__review"
                                to={`/articles/review/${reviewSlug}/`}
                            >
                                <Rating className="game-cover__rating" rating={rating} />
                                {renderReviewTitle()}
                            </Link>
                        ) : (rating && rating !== '0.0' && (
                            <div className="game-cover__review game-cover__review_empty">
                                <Rating className="game-cover__rating" rating={rating} />
                            </div>
                        )
                        )}
                        {isClosed && (
                            <div className="game-cover__closed">
                                <LockSvg className="game-cover__closed-icon" />
                                Проект закрыт
                            </div>
                        )}
                    </div>
                    <Picture
                        classNameWrapper="game-cover__image"
                        src={image}
                        width={872}
                        mobWidth={500}
                        alt={name}
                    />
                </div>
                <div>
                    <ConditionalRenderer ifMobile>
                        <h1 className="game-cover__title_mobile">
                            {tabLabel}
                            {name}
                        </h1>
                    </ConditionalRenderer>
                    <GameRatingMobile
                        className="game-cover__rating_mobile"
                        title="Редакция"
                        rating={rating}
                        link={reviewSlug && `/articles/review/${reviewSlug}/`}
                    />
                </div>
            </div>
            <aside
                className={`game-cover__aside ${movedType ? 'moved' : ''}`}
                data-theme={window.innerWidth < 1200 ? 'light' : 'dark'}
            >
                <h2 className="game-cover__about">
                    <PlanetSvg className="game-cover__icon" />
                    {movedType ? movingParts[movedType]?.main : 'Об игре'}
                    <span className="game-cover__about-sub">{movingParts[movedType]?.sub}</span>
                </h2>
                <div className="game-cover__moving-container">
                    <div className={cn('game-cover__moving-part', {
                        active: !!movedType,
                    })}
                    >
                        <div className="game-cover__moving-part-wrap">
                            {gameInfoMap.map((info) => (
                                <GameInfo
                                    key={info.title}
                                    title={info.title}
                                    list={info.list}
                                    limit={info.limit}
                                    classNameTitle={`game-cover__info-title ${info.classNameTitle}`}
                                    classNameItem={`game-cover__info-item ${info.classNameItem}`}
                                >
                                    {info.children}
                                </GameInfo>
                            ))}
                            <DownloadAside isDark isSmall disabledButtons={getDisabledButtonTypes()} />
                            <Social />
                            <AdminEditLink alias="games" id={id} views={views} />
                        </div>
                    </div>
                    <div className={cn(
                        'game-cover__moving-part',
                        'game-cover__moving-part_absolute',
                        {
                            active: movedType === 'dates-by-region',
                        },
                    )}
                    >
                        <div className="game-cover__moving-part-wrap">
                            <h2 className="game-cover__moving-part-header-mob">Даты выхода по регионам</h2>
                            <div className="game-cover__info-date-container">
                                {renderDatesByRegion()}
                            </div>
                            <button
                                onClick={handleMoreDatesClick}
                                className="game-cover__date-more game-cover__date-more_absolute reversed"
                                data-type=""
                                type="button"
                            >
                                <ArrowSvg className="game-cover__date-more-icon" />
                                Назад
                            </button>
                        </div>
                    </div>
                    <div className={cn(
                        'game-cover__moving-part',
                        'game-cover__moving-part_absolute',
                        {
                            active: movedType === 'dates-by-platform',
                        },
                    )}
                    >
                        <div className="game-cover__moving-part-wrap">
                            <h2 className="game-cover__moving-part-header-mob">Даты выхода по платформам</h2>
                            <div className="game-cover__info-date-container">
                                {renderDatesByPlatform()}
                            </div>
                            <button
                                onClick={handleMoreDatesClick}
                                className="game-cover__date-more game-cover__date-more_absolute reversed"
                                data-type=""
                                type="button"
                            >
                                <ArrowSvg className="game-cover__date-more-icon" />
                                Назад
                            </button>
                        </div>
                    </div>
                </div>
            </aside>
            {description && (
                <div
                    className="game-cover__description_mobile"
                    dangerouslySetInnerHTML={{ __html: description }}
                />
            )}
        </div>
    );
};

export default GameCover;
