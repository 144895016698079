import React from 'react';

interface LazyPreload<Props>
    extends React.LazyExoticComponent<React.ComponentType<Props>> {
    preload: () => Record<string, any>;
}

function lazyPreloadable<Props>(
    factory: () => Promise<{ default: React.ComponentType<Props> }>,
): LazyPreload<Props> {
    return Object.assign(
        React.lazy(factory),
        {
            preload: factory,
        },
    );
}

export default lazyPreloadable;
