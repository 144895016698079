import React from 'react';

import Incarnate from 'molecules/FeedStory/Incarnate';
import Skeleton from 'molecules/FeedStory/Skeleton';

const FeedStory: React.FC = (props) => {
    const { data } = props;

    if (!data) {
        return <Skeleton {...props} />;
    }

    return <Incarnate {...props} />;
};

export default FeedStory;
