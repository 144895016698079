import React, {
    useEffect, useState, useCallback,
} from 'react';

import cn from 'classnames';
import ReactDOM from 'react-dom';

import { useBanners } from 'contexts/banners';
import { useUpdateCounter } from 'contexts/updateCounter';

import { JSONP, runScript } from 'utils/banner';

import useEffectOnScroll from 'hooks/useEffectOnScroll';

import { SITEZONE } from './constants';

import 'scss/skin/banner.scss';

import type { BannerProps } from './types';
import type { ReactNode } from 'react';

const Banner: React.FC<BannerProps> = ({
    type,
    string,
    domNode,
    onEmpty,
    isMobile,
    isDesktop,
    isInstant,
    withTimeout,
    timeout,
    className,
    withPlaceholder,
    shouldReload,
    reloadInterval = 35000,
    withUpdateCounter,
}) => {
    const [banner, setBanner] = useState({});
    const [error, setError] = useState(false);
    const [containerHeigth, setHeight] = useState(withPlaceholder ? 'auto' : 0);
    const [isReady, setReady] = useState(false);
    const { updateCounter } = useUpdateCounter();

    const { slots, checkBannersAvailability } = useBanners();

    const resizeObserver = typeof ResizeObserver === 'function' && new ResizeObserver(initObserver);

    const isDisabled = !checkBannersAvailability();

    const contentRef = useCallback((node) => {
        if (!node) {
            return;
        }

        if (!resizeObserver || +banner.slot === 5752) {
            setHeight('auto');

            return;
        }

        resizeObserver.observe(node);
    }, [type, domNode]);

    useEffect(() => () => {
        if (resizeObserver) {
            resizeObserver.disconnect();
        }
    }, [type, domNode]);

    useEffectOnScroll(() => {
        if (!isInstant && !withTimeout) {
            initBanner();
        }
    }, [type, domNode, isDisabled]);

    useEffect(() => {
        if (withTimeout) {
            setTimeout(initBanner, timeout || 100);

            return;
        }

        if (isInstant) {
            initBanner();
        }
    }, [domNode, isDisabled]);

    useEffect(() => {
        if (!banner.html) {
            return;
        }

        runScript(`slot_${banner.slot}`);
        setTimeout(() => {
            setReady(true);
        }, 400);
    }, [banner.html]);

    useEffect(() => {
        if (!shouldReload) {
            return;
        }

        const interval = setInterval(initBanner, reloadInterval);

        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        if (withUpdateCounter && updateCounter > 0) {
            initBanner();
        }
    }, [updateCounter]);

    function initBanner(): void {
        if (isDisabled) {
            return;
        }

        const typeOne = Array.isArray(type) ? type[0] : type;
        const typeTwo = Array.isArray(type) ? type[1] : null;

        let slotQuery = `q[]=${slots[typeOne]}`;

        if (typeTwo) {
            slotQuery = `${slotQuery}&q[]=${slots[typeTwo]}`;
        }

        const params = [];

        if (string) {
            params.push(`_STRING=${string}`);
        }

        if (params.length) {
            slotQuery += encodeURIComponent(`?${params.join('&')}`);
        }

        const url = `https://ad.mail.ru/adq/?_SITEID=133&${slotQuery}&_SITEZONE=${SITEZONE}`;

        JSONP(url, handleSuccess, handleError);
    }

    function initObserver(entries): void {
        window.requestAnimationFrame(() => {
            if (!Array.isArray(entries) || !entries.length) {
                return;
            }

            const { height } = entries[0].contentRect;

            if (height > 0) {
                setHeight(height);
            }
        });
    }

    function handleSuccess(response): void {
        if (!response[0] || !response[0].html) {
            if (!response[1] || !response[1].html) {
                if (onEmpty) {
                    onEmpty();
                }

                setError(response);

                return;
            }

            // Если баннера нет в первом слоте, ставим из второго
            setBanner({
                html: response[1].html,
                slot: response[1].slot,
            });

            return;
        }

        setBanner({
            html: response[0].html,
            slot: response[0].slot,
        });
    }

    function handleError(response): void {
        if (onEmpty) {
            onEmpty();
        }

        setError(response);
    }

    function renderBanner(): ReactNode {
        const bannerType = Array.isArray(type)
            ? Object.keys(slots).find((key) => slots[key] === +banner.slot)
            : type;

        return (
            <div
                id={`slot_${banner.slot}`}
                className={cn(
                    'banner',
                    `banner_${bannerType}`,
                    className,
                    {
                        banner_mobile: isMobile,
                        banner_desktop: isDesktop,
                        banner_static: withPlaceholder,
                        aside__banner: bannerType?.includes('aside'),
                        [`banner_${bannerType}_visible`]: isReady,
                    },
                )}
                data-rb_slot={banner.slot}
                data-rb_SITEZONE={SITEZONE}
                data-rb_string={string}
                style={{
                    height: containerHeigth,
                }}
            >
                <div
                    ref={contentRef}
                    className="banner__content"
                    dangerouslySetInnerHTML={{ __html: banner.html }}
                />
            </div>
        );
    }

    if (error || isDisabled) {
        return null;
    }

    if (domNode) {
        return ReactDOM.createPortal(renderBanner(), domNode);
    }

    return renderBanner();
};

export default Banner;
