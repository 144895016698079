import React, {
    createContext, useContext, useMemo, useState,
} from 'react';

const PageContext = createContext();

function PageProvider({ children }) {
    const [page, setPage] = useState(false);

    const value = useMemo(() => ({ page, setPage }), [page, setPage]);

    return (
        <PageContext.Provider value={value}>
            {children}
        </PageContext.Provider>
    );
}

function withPage(Component) {
    return (props) => (
        <PageProvider>
            <Component {...props} />
        </PageProvider>
    );
}

function usePage() {
    const context = useContext(PageContext);

    if (context === undefined) {
        throw new Error('usePage must be used within a PageProvider');
    }

    return context;
}

export { PageProvider, usePage, withPage };
