import React, {
    useState, useMemo,
} from 'react';

import cn from 'classnames';

import { plural, metaData } from 'aliases';

import Button from 'atoms/Button';
import Head from 'atoms/Head';

import FeedImage from 'molecules/FeedImage';
import FeedStory from 'molecules/FeedStory';
import GameRequirements from 'molecules/GameRequirements';

import Feed from 'organisms/Feed';

function FeedStoryNoComments(props): React.FC {
    return <FeedStory {...props} noComments />;
}

const fullFeedOptions = {
    limit: 16,
    wideStep: 8,
    paging: 'combined-light',
    isWideStepDynamic: true,
    offset: 0,
    scrollToOnPaging: 'feed__title',
};

const partialFeedOptions = {
    limit: 3,
    paging: 'none',
    wideStep: 0,
};

const GameFeedSection: React.FC = ({
    type, page, name, gameSlug, isFull, initialMeta, requirements, onLoad,
}) => {
    const [isPrerenderReady, setPrerender] = useState(false);
    const [isEmpty, setEmpty] = useState(false);

    function handleFeedLoad(data): void {
        if (!data?.results?.length) {
            setEmpty(true);

            return;
        }

        setEmpty(false);
        onLoad();
        setPrerender(true);
    }

    function renderHead() {
        if (!initialMeta) {
            return null;
        }

        return (
            <Head
                {...initialMeta}
                title={`${metaData.game[type].title} ${name} ${metaData.game[type].subTitle}`}
                description={`${metaData.game[type].title} ${name} 🕹️ ➦ ${metaData.game[type].description} ${name}`}
                canonical={window.location.href}
            />
        );
    }

    function renderSectionHeader(withLinks) {
        if (isFull) {
            return;
        }

        return (
            <div className="game-section__header">
                <h2 className="game-section__title ">
                    {plural.ru[type]}
                    {' '}
                    {name}
                </h2>
                {withLinks && (
                    <>
                        <Button
                            className="isDesktop"
                            label="Смотреть все"
                            href={`/games/${gameSlug}/${type}/`}
                            scrollTo="game-tabs"
                            variant="outline-primary"
                            role="link"
                        />
                        <Button
                            className="isMobile"
                            label="См. все"
                            href={`/games/${gameSlug}/${type}/`}
                            scrollTo="game-main"
                            variant="primary-link"
                            role="link"
                        />
                    </>
                )}
            </div>
        );
    }

    const memoData = useMemo(() => {
        const isImage = type === 'image';

        return {
            key: isImage ? 'image' : 'story',
            type: type === 'articles' ? 'feat' : type,
            qOffset: isImage ? (+page - 1) * 22 : null,
            item: isImage ? FeedImage : FeedStoryNoComments,
            ...(isFull ? fullFeedOptions : partialFeedOptions),
        };
    }, [isFull, type, page]);

    if (type === 'requirements') {
        return (
            <section className={cn('game-section', `game-section-${type}`, { 'game-section_full': isFull })}>
                {renderHead()}
                {renderSectionHeader(false)}
                <GameRequirements requirements={requirements} onLoad={onLoad} />
                { isPrerenderReady && isFull && <div id="prerenderAnchor" /> }
            </section>
        );
    }

    if (isEmpty) {
        return null;
    }

    return (
        <section className={cn('game-section', `game-section-${type}`, { 'game-section_full': isFull })}>
            {renderHead()}
            {renderSectionHeader(true)}
            <Feed
                key={memoData.key}
                limit={memoData.limit}
                type={memoData.type}
                game={gameSlug}
                paging={memoData.paging}
                page={page}
                qOffset={memoData.qOffset}
                FeedItem={memoData.item}
                onLoadEnd={handleFeedLoad}
                isFirstWide={isFull}
                wideStep={memoData.wideStep}
                scrollToOnPaging="game-tabs"
                isWideStepDynamic={memoData.isWideStepDynamic}
            />
            { isPrerenderReady && isFull && <div id="prerenderAnchor" /> }
        </section>
    );
};

GameFeedSection.defaultProps = {
    onLoad: () => null,
};

export default GameFeedSection;
