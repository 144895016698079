import { isObject, isString } from 'typeguards';

import { getErrorMessageById, getErrorMessageFromObject } from './utils';

import type { RequestErrorOptions } from './types';

class RequestError extends Error {
    public status: RequestErrorOptions['status'] | null;

    public body: RequestErrorOptions['body'] | null;

    constructor(options?: RequestErrorOptions) {
        super();

        this.status = options?.status || null;
        this.body = options?.body || null;
    }

    get message() {
        const defaultMessage = `Request error. Status: ${this.status || 'unknown'}`;

        if (!isObject(this.body)) {
            return defaultMessage;
        }

        if (isObject(this.body.message)) {
            return getErrorMessageFromObject(this.body.message);
        }

        if (isString(this.body.message)) {
            // TODO: завести на бэке поле id для использования в качестве ключа
            return getErrorMessageById({
                id: this.body.message,
                defaultMessage: this.body.message,
            });
        }

        return defaultMessage;
    }
}

export default RequestError;
