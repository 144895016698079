export const SLOTS = {
    branding: 5752,
    'branding-video': 803510,
    'branding-gof': 1189408,
    content: 1400449,
    'content-2': 1400467,
    top: 85857,
    bottom: 87779,
    aside: 331953,
    'aside-vkp': 757827,
    fullscreen: 692161,
    'mobile-top': 692162,
    'mobile-content': 1400472,
    'mobile-content-2': 1400517,
    'mobile-bottom': 692163,
    'mobile-fullscreen': 692164,
};

export const DEFAULT_CONTEXT = {
    slots: SLOTS,
    addBannersDisableCondition: () => undefined,
    removeBannersDisableCondition: () => undefined,
    checkBannersAvailability: () => true,
};

export const SITEZONE = 5;
