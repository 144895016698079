import React, { useEffect } from 'react';

const ErrorPage = () => {
    useEffect(() => {
        // eslint-disable-next-line no-console
        console.log('ща буит мясо');

        setTimeout(() => {
            try {
                const a = {};

                // eslint-disable-next-line no-console
                console.log(a.wtf.wtf);
            } catch (e) {
                console.error(e);
            }

            window.myCustomFunction();
        }, 2000);
    }, []);

    return (
        <div>ErrorPage</div>
    );
};

export default ErrorPage;
