export const BUTTON_LABELS: Record<string, string> = {
    buy_pc: 'VK Play', // TODO: переименовать в PC после закрытия ALLIANCE-62021
    buy_xboxone: 'Xbox',
    buy_ps4: 'PS4',
    download_pc: 'PC',
    download: 'Играть бесплатно',
    play: 'Играть бесплатно',
    play_rustore: 'Ru Store',
    play_mymail: 'Мой мир',
    play_facebook: 'FB',
    play_vk: 'Вконтакте',
    play_odk: 'Одноклассники',
    play_appstore: 'App Store',
    play_osx: 'OS X',
    play_winstore: 'Windows Store',
    play_googleplay: 'Google Play',
    play_java: 'Java',
    play_igraem_rf: 'Играем РФ',
    cloud: 'Играть в облаке',
    store: 'VK Play',
};

export const STATIC_BUTTONS = [
    {
        type: 'cloud',
        url: 'https://cloud.vkplay.ru/?utm_source=playerone&utm_campaign=playeronegeneral&utm_medium=gamecard',
        isStatic: true,
    },
];

// TODO: убрать buy_pc после закрытия ALLIANCE-62021
export const ALLOWED_BUTTON_TYPES = ['buy_pc', 'store', 'cloud'];
