import fixISO from 'utils/dates/fixISO';

import { MOSCOW_HOURS_OFFSET } from './constants';

// We have an agreement that on the backend the dates are stored
// in ISO format with a time offset of +3, but this time offset
// is not written into the value.
// We concatenate this timezone offset
// so that the JS Date constructor outputs the data correctly
const getDateFromISO = (isoString: string, offset: number = MOSCOW_HOURS_OFFSET) => {
    if (isoString.includes('Z')) {
        return new Date(isoString);
    }

    const utcOffset = fixISO(isoString, offset);

    return new Date(utcOffset);
};

export default getDateFromISO;
