import { MOSCOW_HOURS_OFFSET } from 'utils/dates/constants';

// We have an agreement that on the backend the dates are stored
// in ISO format with a time offset of +3, but this time offset
// is not written into the value.
// We concatenate this timezone offset
// so that the JS Date constructor outputs the data correctly
const fixISO = (brokenISOString: string, offset: number = MOSCOW_HOURS_OFFSET) => {
    // Format offset to specific UTC format offset for JS Date constructor
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date#date_time_string_format
    // https://tc39.es/ecma262/multipage/numbers-and-dates.html#sec-date-time-string-format
    const sign = offset < 0 ? '-' : '+';
    const absoluteOffset = Math.abs(offset);
    const utcAbsoluteOffset = `0${absoluteOffset}00`.slice(-4);
    const utcOffset = `${sign}${utcAbsoluteOffset}`;

    return `${brokenISOString}${utcOffset}`;
};

export default fixISO;
