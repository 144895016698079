import React, {
    createContext, useContext, useMemo, useState,
} from 'react';

import { DEFAULT_CONTEXT, SLOTS } from 'atoms/Banner/constants';

import type { FC, ReactNode } from 'react';

interface Props {
    children: ReactNode;
}

interface ContextValue {
    slots: Record<string, number>;
    addBannersDisableCondition: (key: string, condition?: boolean) => void;
    removeBannersDisableCondition: (key: string) => void;
    checkBannersAvailability: () => boolean;
}

const BannersContext = createContext<ContextValue>(DEFAULT_CONTEXT);

const BannersProvider: FC<Props> = ({ children }) => {
    const [disableConditions, setDisableConditions] = useState<Record<string, boolean>>({});

    function checkBannersAvailability() {
        return !Object.values(disableConditions).find((condition) => condition);
    }

    const addDisableCondition: ContextValue['addBannersDisableCondition'] = (keyToAdd, condition = true) => {
        setDisableConditions((prevConditions) => ({
            ...prevConditions,
            [keyToAdd]: condition,
        }));
    };

    const removeDisableCondition: ContextValue['removeBannersDisableCondition'] = (keyToRemove) => {
        const filteredConditions = Object.fromEntries(
            Object.entries(disableConditions).filter(([key]) => key !== keyToRemove),
        );

        setDisableConditions(filteredConditions);
    };

    const value = useMemo(() => ({
        slots: SLOTS,
        checkBannersAvailability,
        addBannersDisableCondition: addDisableCondition,
        removeBannersDisableCondition: removeDisableCondition,
    }), [disableConditions]);

    return (
        <BannersContext.Provider value={value}>
            {children}
        </BannersContext.Provider>
    );
};

const withBanners = (Component: FC): FC => (props) => (
    <BannersProvider>
        <Component {...props} />
    </BannersProvider>
);

function useBanners() {
    const context = useContext(BannersContext);

    if (context === undefined) {
        throw new Error('useBanners must be used within a BannersProvider');
    }

    return context;
}

export { withBanners, useBanners };
