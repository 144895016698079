import React, { memo, Suspense } from 'react';

import ReactDOM from 'react-dom';

import 'scss/skin/widget.scss';

const Gallery = React.lazy(() => import('organisms/Gallery'));
const Gif = React.lazy(() => import('organisms/Gif'));
const VideoGallery = React.lazy(() => import('organisms/VideoGallery'));
const Video = React.lazy(() => import('./widgets/Video'));
const Poll = React.lazy(() => import('./widgets/Poll'));
const BetaChoice = React.lazy(() => import('./widgets/BetaChoice'));
const Parallax = React.lazy(() => import('./widgets/Parallax'));
const VKRetarget = React.lazy(() => import('./widgets/VKRetarget'));
const MiniGame = React.lazy(() => import('./widgets/MiniGame'));

function Widget({
    type, domNode, compoundData, ...otherProps
}) {
    function renderWidget() {
        // простые виджеты

        if (type === 'poll') {
            return <Poll {...otherProps} />;
        }

        if (type === 'image') {
            const { id, index, ...images } = otherProps;

            return <Gallery id={id} index={index} images={[images]} />;
        }

        if (type === 'video') {
            return <Video {...otherProps} />;
        }

        if (type === 'gif') {
            return <Gif {...otherProps} />;
        }

        if (type === 'beta-choice') {
            return <BetaChoice {...otherProps} />;
        }

        if (type === 'parallax') {
            return <Parallax {...otherProps} />;
        }

        if (type === 'vk-retarget') {
            return <VKRetarget {...otherProps} />;
        }

        // составные виджеты

        if (type === 'gallery') {
            return <Gallery {...otherProps} images={compoundData} />;
        }

        if (type === 'video-gallery') {
            return <VideoGallery {...otherProps} videos={compoundData} />;
        }

        if (type === 'mini-game') {
            return <MiniGame {...otherProps} />;
        }

        return null;
    }

    function renderSuspendedWidget() {
        return (
            <Suspense fallback={null}>
                {renderWidget()}
            </Suspense>
        );
    }

    if (!domNode) {
        return renderSuspendedWidget();
    }

    return ReactDOM.createPortal(renderSuspendedWidget(), domNode);
}

export default memo(Widget);
