import React, { useCallback, useState } from 'react';

import classNames from 'classnames';

import { stripTags } from 'src/neurons';

import type { QuizQuestionProps } from 'organisms/Quiz/types';
import type { FC, SyntheticEvent } from 'react';

const QuizQuestion: FC<QuizQuestionProps> = ({
    text,
    answers,
    isCurrent,
    rightAnswer,
    onAnswer,
}) => {
    const [localAnswer, setLocalAnswer] = useState<string>('');

    const handleAnswer = useCallback((event: SyntheticEvent<HTMLButtonElement>) => {
        const { id } = event.currentTarget.dataset;

        const idNumber = Number(id);

        if (rightAnswer || Number.isNaN(idNumber)) {
            return;
        }

        onAnswer(idNumber);
        setLocalAnswer(id || '');
    }, [rightAnswer]);

    if (!answers?.length) {
        return null;
    }

    return (
        <div className={classNames('quiz-widget__question', {
            current: isCurrent,
        })}
        >
            <p className="quiz-widget__question-title">
                {text}
            </p>
            <div className="quiz-widget__question-answers">
                {answers?.map((answer) => (
                    <button
                        key={answer.id}
                        className={classNames('quiz-widget__question-answer', {
                            right: rightAnswer === `${answer.id}`,
                            wrong: rightAnswer && localAnswer === `${answer.id}` && rightAnswer !== localAnswer,
                        })}
                        data-id={answer.id}
                        type="button"
                        onClick={handleAnswer}
                    >
                        {answer.text}
                        {answer.description && (
                            <span className="quiz-widget__question-description">
                                {stripTags(answer.description)}
                            </span>
                        )}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default QuizQuestion;
