import React, { useEffect, useRef } from 'react';

import baron from 'baron';
import cn from 'classnames';

import 'scss/skin/scrollable-wrapper.scss';

interface Props {
    children: React.Children;
    className?: string;
    id?: string;
    isAlwaysActive: boolean;
}

const ScrollableWrapper: React.FunctionComponent = ({
    children, className, id, isAlwaysActive,
}: Props) => {
    const renderScroller = window.isGamecenter;
    const wrapper = useRef();
    const scrollerElem = useRef();
    const suffix = Math.random().toString(36).substr(2, 9);
    const jsClasses = {
        root: `js-gc-root-${id || suffix}`,
        scroller: `js-gc-scroller-${id || suffix}`,
        track: `js-gc-track-${id || suffix}`,
        bar: `js-gc-bar-${id || suffix}`,
    };

    let scroller;

    const initScroller = (): void => {
        scroller = baron({
            root: `.${jsClasses.root}`,
            scroller: `.${jsClasses.scroller}`,
            track: `.${jsClasses.track}`,
            bar: `.${jsClasses.bar}`,
            barOnCls: 'active',
        });
    };

    const disposeScroller = () => scroller?.dispose();

    useEffect(() => {
        if (renderScroller && wrapper.current) {
            initScroller();
        }

        return (): void => {
            if (renderScroller && wrapper.current) {
                disposeScroller();
            }
        };
    }, [renderScroller]);

    if (renderScroller) {
        return (
            <div
                ref={wrapper}
                className={cn('scrollbar__wrapper', jsClasses.root, { [className]: className, active: isAlwaysActive })}
            >
                <div
                    ref={scrollerElem}
                    className={cn('scrollbar__scroller', jsClasses.scroller)}
                    {...id && { id }}
                >
                    {children}
                </div>
                <div className="scrollbar__track-container">
                    <div className={cn('scrollbar__track', jsClasses.track)}>
                        <div className={cn('scrollbar__bar', jsClasses.bar)} />
                    </div>
                </div>
            </div>
        );
    }

    return children;
};

export default ScrollableWrapper;
