import React, {
    useState, useEffect, useRef,
} from 'react';

import { plural } from 'aliases';
import getGameImageList from 'src/api/v4/getGameImageList';
import noop from 'utils/noop';

import Button from 'atoms/Button';

import Widget from '../Widget';

import type { FC } from 'react';

const widgetsMap = {
    image: 'gallery',
    video: 'video-gallery',
};

const slidesNumMap = {
    image: 4,
    video: 3,
};

const isMobile = window.innerWidth < 1200;

interface Props {
    type: 'image',
    gameSlug: string
    isFull?: boolean
    onLoad: () => void;
    name: string
}

const GameSliderSection: FC<Props> = ({
    type,
    gameSlug,
    isFull,
    onLoad = noop,
    name,
}) => {
    const [items, setItems] = useState(null);
    const sectionRef = useRef(null);

    const fetchImage = async (slug: string) => {
        try {
            const response = await getGameImageList({
                pathParameters: { slug },
                queryParameters: { limit: 9 },
            });

            setItems(response.results.slice(0, 8));

            onLoad();
        } catch (error) {
            // ignore
        }
    };

    useEffect(() => {
        fetchImage(gameSlug);
    }, [gameSlug]);

    if (!items) {
        return null;
    }

    return (
        <section className="game-section game-section-slider" ref={sectionRef}>
            <div className="game-section__header">
                <h2 className="game-section__title ">
                    {plural.ru[type]}
                    {' '}
                    {name}
                </h2>
                {!isFull && (
                    <>
                        <Button
                            className="isDesktop"
                            label="Смотреть все"
                            href={`/games/${gameSlug}/${type}/`}
                            scrollTo="game-tabs"
                            variant="outline-primary"
                            role="link"
                        />
                        <Button
                            className="isMobile"
                            label="См. все"
                            href={`/games/${gameSlug}/${type}/`}
                            scrollTo="game-main"
                            variant="primary-link"
                            role="link"
                        />
                    </>
                )}
            </div>
            <Widget
                type={widgetsMap[type]}
                id={`widget-${type}`}
                compoundData={items}
                slidesToShow={isMobile ? 1 : slidesNumMap[type]}
                isInfinite={false}
                isMini
            />
        </section>
    );
};

export default GameSliderSection;
