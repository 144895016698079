import React from 'react';

import 'scss/skin/aside.scss';

import type { FC } from 'react';

const Sticky: FC = ({ children }) => (
    <div className="aside__sticky-container">
        <section className="aside__sticky">
            {children}
        </section>
    </div>
);

export default Sticky;
