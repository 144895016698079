// TODO: удалить, когда появится второй экспорт
// eslint-disable-next-line import/prefer-default-export
export const messages = {
    ru: {
        title: 'Страница не найдена',
        error: 'Ошибка 404',
        text: 'Вероятно, был указан неправильный адрес страницы, либо страница была удалена или перемещена.',
    },
    en: {
        title: 'Page not found',
        error: 'Error 404',
        text: 'You\'ve probably followed the wrong link, or the page has been moved or removed.',
    },
};
