import React from 'react';

import getSrcSet from 'atoms/Picture/utils/getSrcSet';

import type { BaseProps } from 'atoms/Picture/types';
import type { FC } from 'react';

interface Props extends Pick<BaseProps, 'src' | 'width' | 'height' | 'mobHeight' | 'mobWidth' | 'quality' | 'noGif'> {
    size: number;
    type: string;
    mediaQuery?: string;
}

const MediaSource: FC<Props> = ({
    src,
    type,
    size,
    height,
    width,
    mediaQuery,
    mobHeight,
    mobWidth,
    quality,
    noGif,
}) => {
    const isMobile = mediaQuery === 'max-width';

    const srcSet = getSrcSet({
        src,
        type,
        width: isMobile ? (mobWidth || width) : width,
        height: isMobile ? (mobHeight || height) : height,
        quality,
        noGif,
    });

    return (
        <source
            type={`image/${type}`}
            media={mediaQuery ? `(${mediaQuery}: ${size}px)` : undefined}
            srcSet={srcSet}
        />
    );
};

export default MediaSource;
