import { useEffect, useState } from 'react';

const desktopWidth = 1200;
const mobileWidth = 1199;

const defaultIsMobile = window.innerWidth < desktopWidth;
const defaultIsDesktop = window.innerWidth > mobileWidth;

const isIOS = ((): boolean => ([
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod',
].includes(navigator.platform)
// iPad on iOS 13 detection
|| (navigator.userAgent.includes('Mac') && 'ontouchend' in document)))();

export default function useDeviceType(): number {
    const [windowWidth, setWindowWidth] = useState(undefined);
    const [isMobile, setIsMobile] = useState(defaultIsMobile);
    const [isDesktop, setIsDesktop] = useState(defaultIsDesktop);

    function handleResize(): void {
        setWindowWidth(window.innerWidth);
        setIsMobile(window.innerWidth < desktopWidth);
        setIsDesktop(window.innerWidth > mobileWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize, { passive: true });

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return {
        isMobile, isDesktop, windowWidth, isIOS,
    };
}
