import { isObject, isString } from 'typeguards';

import { ERROR_MESSAGES_MAP } from './constants';

import type { ExtendedRequestBody, RequestBodyData } from './types';

export const getRequestBodyData = (body: ExtendedRequestBody): RequestBodyData => {
    const isDefaultBodyType = body instanceof Blob || body instanceof FormData || body instanceof URLSearchParams;

    if (!isDefaultBodyType && isObject(body)) {
        return {
            isJson: true,
            body: JSON.stringify(body),
        };
    }

    return {
        isJson: false,
        body,
    };
};

export const isAbortError = (error: unknown): boolean => (
    error instanceof DOMException
    && error.name === 'AbortError'
);

export const getErrorMessageById = (
    { id, defaultMessage }: { id: string, defaultMessage: string },
) => ERROR_MESSAGES_MAP[id] || defaultMessage;

export const getErrorMessageFromObject = (object: Record<string, unknown>) => {
    const messagesArray: string[] = Object.entries(object).map(([key, value]) => {
        if (Array.isArray(value)) {
            if (isString(value[0])) {
                const id = `${key}_${value[0]}`;

                return getErrorMessageById({ id, defaultMessage: id });
            }

            if (isObject(value[0])) {
                return getErrorMessageFromObject(value[0]);
            }

            return '';
        }

        return '';
    });

    return messagesArray.join('\n');
};
