// eslint-disable-next-line import/prefer-default-export
export const JSON_BODY_HEADERS = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
};

export const ERROR_MESSAGES_MAP: Record<string, string> = {
    'Incorrect revision': 'Кто-то уже отредактировал этот материал.\nПерезагрузите страницу',
    'title_Это поле не может быть пустым.': 'Добавьте заголовок',
    'picture_Это поле не может быть пустым.': 'Добавьте обложку',
    'json_Это поле не может быть пустым': 'Добавьте текст',
};
