/* TODO replace render functions with components */
/* eslint-disable react/jsx-no-bind */
import React from 'react';

import Aside from 'atoms/Aside';
import Banner from 'atoms/Banner';
import ConditionalRenderer from 'atoms/ConditionalRenderer';

import { EditorialBannerRight } from 'molecules/EditorialBanners';

import type { FC, ReactNode } from 'react';

interface Props {
    staticTopContent: ReactNode;
    stickyBottomContent: ReactNode;
}

const HomeAside: FC<Props> = ({ staticTopContent, stickyBottomContent }) => (
    <ConditionalRenderer ifDesktop>
        <Aside>
            <Aside.Static>
                {staticTopContent}
                <Banner
                    type="aside-vkp"
                    withTimeout
                    timeout={1000}
                    withPlaceholder
                />
            </Aside.Static>
            <Aside.Sticky>
                <EditorialBannerRight />
                {stickyBottomContent}
            </Aside.Sticky>
        </Aside>
    </ConditionalRenderer>
);

export default HomeAside;
