import React from 'react';

import type { CSSProperties, FC } from 'react';

const style = {
    width: 0,
    height: 0,
    position: 'absolute',
    visibility: 'hidden',
} as CSSProperties;

interface Props {
    src?: string;
}

const Pixel: FC<Props> = ({ src }) => {
    if (!src) {
        return null;
    }

    return (
        <img src={src} style={style} alt="" />
    );
};

export default Pixel;
