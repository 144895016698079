import React, { useEffect, useMemo } from 'react';

import cn from 'classnames';

import { withBanners, useBanners } from 'contexts/banners';
import DeviceContext from 'contexts/device';
import { usePage } from 'contexts/page';
import { UpdateCounterProvider } from 'contexts/updateCounter';

import Router from 'routers/media.vkplay.ru/Router';

import Banner from 'atoms/Banner';
import ConditionalRenderer from 'atoms/ConditionalRenderer';
import ScrollableWrapper from 'atoms/ScrollableWrapper';

import useDeviceType from 'hooks/useDeviceType';

import {
    isGamecenter,
    isSTB,
    isSafari,
} from 'neurons';

import initJsonLd from './json-ld';

window.isSafari = isSafari();
window.isSTB = isSTB();
window.isGamecenter = isGamecenter();

const AppVKP: React.FC = () => {
    const { page } = usePage();
    const {
        addBannersDisableCondition,
    } = useBanners();

    useEffect(() => {
        addBannersDisableCondition('is-gc-app', window.isGamecenter);
        addBannersDisableCondition('is-mg-app', window.isMyGamesApp);
        addBannersDisableCondition('is-stb-app', window.isSTB);
    }, []);

    const {
        isMobile, isDesktop, windowWidth, isIOS,
    } = useDeviceType();

    useEffect(() => {
        initJsonLd.app();
        initJsonLd.organization();
    }, []);

    const deviceContextValue = useMemo(() => {
        window.gamesMailRuAppConfig.isMobile = isMobile;

        return {
            isMobile, isDesktop, windowWidth, isIOS,
        };
    }, [isMobile, isDesktop, windowWidth]);

    return (
        <ScrollableWrapper id="gc-scrollbar">
            <UpdateCounterProvider>
                <DeviceContext.Provider value={deviceContextValue}>
                    <React.Suspense fallback={null}>
                        {!window.isMyGamesApp && !window.isGamecenter && (
                            <div id="vkp-header">
                                <span className="mock-subnav" />
                            </div>
                        )}
                    </React.Suspense>
                    <div className={cn(
                        'app-content',
                        {
                            'app-content_superapp': window.isMyGamesApp,
                        },
                    )}
                    >
                        <div className="grey-mass" />
                        <ConditionalRenderer ifDesktop>
                            {page === 'gof' ? (
                                <Banner
                                    type="branding-gof"
                                    isDesktop
                                    isInstant
                                    withUpdateCounter
                                />
                            ) : (
                                <Banner
                                    type="branding"
                                    isDesktop
                                    isInstant
                                    withUpdateCounter
                                />
                            )}
                        </ConditionalRenderer>
                        <Router />
                    </div>
                    <div id="vkp-footer" className="footer" />
                    <ConditionalRenderer
                        noRenderWithParam={['fromSA', 'from=newsapp', 'from=special', 'from=informer']}
                        ifMobile
                        ifNotPWA
                    >
                        <Banner
                            type="mobile-fullscreen"
                            isMobile
                            isInstant
                        />
                    </ConditionalRenderer>
                    <ConditionalRenderer
                        noRenderWithParam={['fromSA', 'from=newsapp', 'from=special', 'from=informer']}
                        ifDesktop
                        ifNotPWA
                    >
                        <Banner
                            type="fullscreen"
                            isDesktop
                            isInstant
                        />
                    </ConditionalRenderer>
                </DeviceContext.Provider>
            </UpdateCounterProvider>
        </ScrollableWrapper>
    );
};

export default React.memo(withBanners(AppVKP));
