import { isArticleAlias } from 'aliases';

const { restDomain, desktopHost } = window.gamesMailRuAppConfig;

interface FetcherOptions extends RequestInit {
    nativeError?: boolean;
}

interface Fetcher {
    <T>(url: string, options?: FetcherOptions & {nativeError: false | never}): Promise<T | Error>
    <T>(url: string, options?: FetcherOptions & {nativeError: true}): Promise<T>
    <T>(url: string, options?: FetcherOptions): Promise<T | Error>
}

export const fetcher: Fetcher = async (url, options) => {
    const { nativeError, ...rest } = options || {};

    try {
        const response = await fetch(url, { credentials: 'include', ...rest });

        if (nativeError && !response.ok) {
            const json = await response.json();

            const data = {
                cause: {
                    status: response.status,
                    statusText: response.statusText,
                    message: json.message,
                },
            };

            throw new Error(response.statusText, data);
        }

        return await response.json();
    } catch (error) {
        if (options && 'nativeError' in options && nativeError === true) {
            throw new Error(error, { cause: error.cause });
        }

        return new Error(error);
    }
};

function getStoryRestUrl(alias: string, slug: string, type: string, draftId: string): string {
    if (draftId) {
        return `//${restDomain}/pc/article/draft/${draftId}`;
    }

    const modifier = type === 'article' ? `${alias}/` : '';

    return `//${restDomain}/pc/v2/${type}/${modifier}${slug}/`;
}

export function getStoryUrl(alias, slug, date, isCanon): string {
    if (alias.includes('ugc')) {
        return `/${alias}/${slug}/`;
    }

    if (alias === 'gof') {
        return `/gof/${slug}/`;
    }

    if (alias === 'review') {
        return `/articles/${alias}/${slug}/`;
    }

    const host = isCanon ? `https://${desktopHost}` : '';
    const base = isArticleAlias(alias) ? `articles/${alias}` : alias;
    const splittedDate = date.includes('T') ? date.split('T')[0] : date.split(' ')[0];
    const modifier = alias === 'news' ? `${splittedDate}/` : '';

    return `${host}/${base}/${modifier}${slug}/`.replace('v2/', '');
}

// Deprecated
export function getStoryData(alias: string, slug: string, type: string, draftId: string): Promise {
    const url = getStoryRestUrl(alias, slug, type, draftId);

    return fetcher(url);
}

interface StoryData {
    slug: string;
    alias: string;
    short?: boolean;
}

// Deprecated
// Used only for review in the GameCover. Should be refactored
export function getStoryDataV3({
    slug, alias, short,
}: StoryData): Promise {
    const shortUrlPart = short ? 'short/' : '';

    return fetcher(`//${restDomain}/pc/v3/story/${alias}/${slug}/${shortUrlPart}`);
}

// Deprecated
export function getStoryInfo(alias: string, slug: string, type: string, draftId: string): Promise {
    const url = getStoryRestUrl(alias, slug, type, draftId);

    return fetcher(`${url}short/`);
}

// Deprecated
export function getAuthorData(alias: string, slug: string, type: string): Promise {
    const url = getStoryRestUrl(alias, slug, type);

    return fetcher(`${url}author/`);
}

// Deprecated
export function getFileData(alias: string, slug: string, type: string, draftId: string): Promise {
    const url = getStoryRestUrl(alias, slug, type, draftId);

    return fetcher(url.replace('v2/', 'v3/'));
}

// Deprecated
export function getHotGames(isIOS): Promise {
    const platform = isIOS ? 'ios' : 'android';

    return fetcher(`//${restDomain}/play/pockets/mobile-${platform}-hot/`);
}

export function getVimeoInfo(videoSrc): Promise {
    const url = `https://vimeo.com/api/oembed.json?url=https:${encodeURI(videoSrc)}`;

    return fetcher(url, { credentials: 'omit' });
}

// Deprecated
export function getTopBanner(): Promise {
    return fetcher(`https://${restDomain}/pc/v3/pockets/banner_top/`);
}

export function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);

    if (parts.length === 2) {
        return parts.pop().split(';').shift();
    }
}

export function getYoutubeId(url): string {
    if (typeof url !== 'string') {
        return '';
    }

    // eslint-disable-next-line no-useless-escape
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);

    if (match && match[2].length === 11) {
        return match[2];
    }

    return '';
}

export function debounce(callback, wait) {
    let timeout;

    return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => callback.apply(this, args), wait);
    };
}

export function relativize(url: string): string {
    try {
        return new URL(url).pathname;
    } catch (err) {
        console.error(err);

        return url;
    }
}

export function initTwitterTranslation() {
    const tweetNodes = document.getElementsByClassName('w-tweet');

    for (let i = tweetNodes.length - 1; i >= 0; --i) {
        setTweetTranslation(tweetNodes[i]);
        tweetNodes[i].remove();
    }
}

async function setTweetTranslation(node) {
    const { translateId } = node.dataset;

    const results = await fetch(`//${restDomain}/pc/social_translation/${translateId}/`);
    const translation = await results.json();

    let counter = 0;

    // проверяем наличие твита в течение 30 секунд
    const interval = setInterval(() => {
        if (counter >= 30) {
            clearInterval(interval);
        }

        const tweetWebComponent = document.querySelector(`twitter-widget[data-tweet-id="${translation.message_id}"]`);
        const tweetIframe = document.querySelector(`iframe[data-tweet-id="${translation.message_id}"]`);

        if (tweetWebComponent) {
            setWCTranslation(tweetWebComponent, translation.text);
            clearInterval(interval);

            return;
        }

        if (tweetIframe) {
            setITranslation(tweetIframe, translation.text);
            clearInterval(interval);

            return;
        }

        counter += 1;
    }, 1000);
}

function setWCTranslation(node, translation) {
    const tweetText = node.shadowRoot.querySelectorAll('.Tweet-text')[0];

    if (!tweetText) {
        return false;
    }

    tweetText.innerHTML = translation;
}

function setITranslation(node, translation) {
    const newDiv = document.createElement('div');
    newDiv.innerHTML = translation;
    newDiv.classList.add('tweet__translation');
    node.parentNode.insertBefore(newDiv, node.nextSibling);
}

export function stripTags(str): string {
    return str?.replace(/<\/?[^>]+(>|$)/g, '');
}

export function getLocalStorage(key) {
    let result = null;

    try {
        result = window.localStorage?.getItem(key);
    } catch (e) {
        console.warn(`Local Storage Error: ${e.message}`);
    }

    return result;
}

export function setLocalStorage(key, data) {
    const value = typeof data === 'string' ? data : JSON.stringify(data);

    window.localStorage?.setItem(key, value);
}

export function removeLocalStorage(key) {
    window.localStorage?.removeItem(key);
}

export function isSafari(): boolean {
    const safariRegex = /^((?!chrome|android).)*safari/i;

    return navigator.userAgent.match(safariRegex) !== null;
}

export function isGamecenter(): boolean {
    return navigator.userAgent.match(/\bDownloader\/\d+\b/) !== null;
}

// STB-приставка
export function isSTB(): boolean {
    const stbModelsRegex = /STB122A|IP6003|B866V2|Switron-i12A|Switron-i12E|GNC/gmi;

    return navigator.userAgent.match(stbModelsRegex) !== null;
}

export function getSearchParameter(name) {
    const match = RegExp(`[?&]${name}=([^&]*)`).exec(window.location.search);

    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

export function widgetAttrParse(data, widgetId) {
    let result = null;
    const id = widgetId ? ` (id: ${widgetId})` : '';

    try {
        result = JSON.parse(data);
    } catch (e) {
        console.error(`An error occurred while parsing widget attribute${id}: ${e.stack}`);
    }

    return result;
}

// TODO: избавиться, оставив только getLocalStorage
export function getReadThisWeek() {
    let readThisWeek = [];

    try {
        readThisWeek = !!window.localStorage && JSON.parse(window.localStorage.getItem('pg-read-articles'));

        if (!readThisWeek) {
            readThisWeek = [];
        }
    } catch (err) {
        console.error('Error parsing pg-read-articles:', err.message);
    }

    return readThisWeek;
}

export function setReadThisWeek(data) {
    const eightDays = 6.912e+8;
    const currentTime = Date.now();

    let readThisWeek = getReadThisWeek();
    let readIDs = Object.keys(readThisWeek);

    // убираем из прочитанных статей те, которые юзер читал более 8 дней назад
    readIDs = readIDs.filter((id) => +currentTime - +readThisWeek[id] < eightDays);
    // eslint-disable-next-line no-return-assign,no-sequences
    readThisWeek = readIDs.reduce((res, id) => ((res[id] = readThisWeek[id]), res), {});

    let newList = data;

    if (Object.keys(readThisWeek).length) {
        newList = { ...readThisWeek, ...data };
    }

    setLocalStorage('pg-read-articles', newList);
}
